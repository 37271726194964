import {
  allChannelsCategories,
  componentSelect,
  getPluginStatus,
  infoComponentSelect,
  spiritCategoryOptions,
  allCategories,
  articleCategoryAll,
  companyCategoryAll,
  learnCategoryAll,
  shopCategoryAll,
  spiritOptions,
  bannerCategoryAll,
  collegeCategoryOptions,
  getCityJson,
} from '../../api/components'
import store from '@/base/store'
import Vue from 'vue'

import organization from './updates/st-join-organize'
import {getAllSources} from '@/modules/article-admin/api/write-article/article-left'
const update = {
  'st-join-organize': organization['st-join-organize'],
  /**
   * 不能有空格
   * @param {object} vm 表单的vue实例对象
   */
  'st-page-set': {
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })

          // if (store.state.tenant.features.includes('consult')) {
          //   console.log('可以显示咨询客服按钮功能')
          // }
          _this.rulesArr.consultBtn.isShow =
            store.state.tenant.features.includes('consult') ? 1 : 0
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image'
        }
      },
    },
    header: {
      template: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.form.height =
            fApi.form.template === 'circle'
              ? 180
              : fApi.form.template === 'square'
              ? 0
              : fApi.form.height
          // fApi.form.radian = fApi.form.template === 'circle' ? 70 : fApi.form.template === 'square' ? 0 : fApi.form.radian
          fApi.form.radian = fApi.form.template === 'circle' ? 70 : 0
        }
      },
      height: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.template === 'custom' ? true : false
        }
      },
      radian: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.template === 'custom' ? true : false
          // rule.display = false
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
      bgPosition: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.bgType === 'image'
        }
      },
      upBgColor: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.upBgType === 'color'
        }
      },
      upBgImage: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.upBgType === 'image'
        }
      },
    },
    serviceBtn: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          // TODO：踩坑：form-create.json serviceBtn.isShow 默认得是1，否则这里的修改不生效
          vm.rulesArr.serviceBtn.isShow =
            vm.$route.params?.platform === 'mp-weixin' ? 1 : 0
        }
      },
    },
  },

  'st-nav': {
    showConfig: {
      showType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          _this.rulesArr.tabs.rules[0].props.limit =
            val === 'template3' ? 5 : 12
          _this.rulesArr.tabs.isShow = !['template1', 'template2'].includes(val)
            ? 1
            : 0
        }
      },
    },
  },
  // 云相册详情
  'st-activity-album-detail': {
    template: {
      template: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // 选择模板二才显示图片排版
          _this.rulesArr.photoConfig.isShow = ['template2'].includes(val)
            ? 1
            : 0
        }
      },
    },
  },

  'st-start-page': {
    normal: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          // 默认显示的是轮播
          const isDefault = !['video', 'image'].includes(fApi.form.showType)
          vm.rulesArr.normal.rules.forEach((el, index) => {
            if (el.field === 'jump_link') {
              el.display = !isDefault && val === 1
            } else if (index > 1) {
              el.display = isDefault && val === 1
            } else if (index === 1) {
              /* 显示类型 */
              el.display = val === 1
            }
          })
          /* 视频 */
          vm.rulesArr.normal.rules[2].display =
            fApi.form.showType === 'video' && val === 1
          /* 图片 */
          vm.rulesArr.normal.rules[3].display =
            fApi.form.showType === 'image' && val === 1



          // 新代码
          // vm.rulesArr.normal.rules.forEach(rule => {
          //   /* 图片 */
          //   if (rule.field === 'image') {
          //     rule.display = fApi.form.showType === 'image' && val === 1
          //     vm.rulesArr.dataConfig.isShow = 1 && val === 1;
          //   }
          //   /* 视频 */
          //   if (rule.field === 'video') {
          //     rule.display = fApi.form.showType === 'video' && val === 1
          //   }
          //
          //   if (val === 0) {
          //     if (rule.field !== 'isShow') rule.display = false
          //     // 总开关关闭，都隐藏
          //     vm.rulesArr.dataConfig.isShow = 0
          //   }
          // })
        }
      },
      showType: (vm) => {
        return (val, rule, fApi) => {
          const isDefault = !['video', 'image', 'startPage'].includes(val)

          vm.rulesArr.normal.rules.forEach((r, rI) => {
            if (rI > 1) r.display = isDefault && fApi.form.isShow === 1
          })
          vm.rulesArr.normal.rules[2].display =
            val === 'video' && fApi.form.isShow === 1
          vm.rulesArr.normal.rules[3].display =
            val === 'image' && fApi.form.isShow === 1

          vm.rulesArr.normal.rules.forEach((r, rI) => {
            if (r.field === 'jump_link') {
              r.display = fApi.form.isShow === 1 && !isDefault
            }
          })

          vm.rulesArr.dataConfig.rules[0].display =
            ['startPage'].includes(val) && fApi.form.isShow === 1
          vm.rulesArr.dataConfig.rules[1].display =
            ['startPage', 'image'].includes(val) && fApi.form.isShow === 1

          vm.rulesArr.normal.rules[8].display = val !== 'startPage'
          // 如果都隐藏了配置，就把config也隐藏了
          console.log(vm.rulesArr.dataConfig, 'vm')
          if (
            !vm.rulesArr.dataConfig.rules[0].display &&
            !vm.rulesArr.dataConfig.rules[1].display
          ) {
            vm.rulesArr.dataConfig.isShow = 0
          } else {
            vm.rulesArr.dataConfig.isShow = 1
          }
          console.log(vm.rulesArr.normal.rules[8].display)
        }
      },
      // 新代码
    //   showType: (vm) => {
    //     return (val, rule, fApi) => {
    //       // 是默认的图片轮播模式
    //       const isDefault = !['video', 'image', 'startPage'].includes(val)
    //
    //       // vm.rulesArr.normal.rules.forEach((r, rI) => {
    //       //   if (rI > 1) r.display = isDefault && fApi.form.isShow === 1
    //       // })
    //
    //       vm.rulesArr.normal.rules.forEach(rule => {
    //         /* 图片 */
    //         if (rule.field === 'image') {
    //           rule.display = fApi.form.showType === 'image' && fApi.form.isShow === 1
    //           vm.rulesArr.dataConfig.isShow = fApi.form.isShow === 1;
    //         }
    //         /* 视频 */
    //         if (rule.field === 'video') {
    //           rule.display = fApi.form.showType === 'video' && fApi.form.isShow === 1
    //           vm.rulesArr.dataConfig.isShow = 0
    //         }
    //       })
    //
    //       vm.rulesArr.normal.rules[2].display =
    //         val === 'video' && fApi.form.isShow === 1
    //       vm.rulesArr.normal.rules[3].display =
    //         val === 'image' && fApi.form.isShow === 1
    //
    //       vm.rulesArr.normal.rules.forEach((r, rI) => {
    //         if (r.field === 'jump_link') {
    //           r.display = fApi.form.isShow === 1 && val !== 'startPage' && val !== 'default'
    //         }
    //       })
    //
    //       vm.rulesArr.dataConfig.rules[0].display =
    //         ['startPage', 'image'].includes(val) && fApi.form.isShow === 1
    //       vm.rulesArr.dataConfig.rules[1].display =
    //         ['startPage', 'image'].includes(val) && fApi.form.isShow === 1
    //
    //       vm.rulesArr.normal.rules[8].display = val !== 'startPage'
    //       // 如果都隐藏了配置，就把config也隐藏了
    //       console.log(vm.rulesArr.dataConfig, 'vm')
    //       if (
    //         !vm.rulesArr.dataConfig.rules[0].display &&
    //         !vm.rulesArr.dataConfig.rules[1].display
    //       ) {
    //         vm.rulesArr.dataConfig.isShow = 0
    //       } else {
    //         vm.rulesArr.dataConfig.isShow = fApi.form.isShow === 1 ? 1 : 0
    //       }
    //     }
    //   },
    },
    dataConfig: {
      skipTime: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
        }
      },
      bannerCategory: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm

          if (rule.props.options.length === 0) {
            // rule.props.options = store.state.appDesign.brandCategoryOptions

            bannerCategoryAll({})
              .then((res) => {
                // console.log(
                //   res.data,
                //   rule.value,
                //   '123123123123',
                //   res.data.length !== rule.props.options.length,
                //   rule.value.length
                // )
                // if (res.data.length !== rule.props.options.length) {
                //   // 清空之前的所有选项
                //   rule.value = []
                // }
                rule.props.options = res.data
                if (!rule.value.length) {
                  rule.value = res.data.map((item) => item.id)
                }
              })
              .catch((err) => {})
          }
        }
      },
    },
  },

  'st-login-page': {
    normal: {
      agreementTitle: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.form.agreementTitle = val ? val : '《用户使用条款》'
        }
      },
    },
  },

  'st-brand-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          _this.rulesArr.dataConfig.rules[4].display = val === 'template1'
          _this.rulesArr.templateData.rules[1].display = val !== 'template4'
          _this.rulesArr.templateData.rules[2].display = [
            'template4',
            'template5',
            'template6',
          ].includes(val)
          _this.myValue.loadingConfig.loadingType =
            val === 'template3'
              ? _this.myValue.loadingConfig.loadingType
              : 'none'
          let filterKey = ['loadingType', 'handleType', 'handleText']
          if (!['template3', 'template5', 'template6'].includes(val)) {
            _this.rulesArr.loadingConfig.rules.forEach((el) => {
              if (filterKey.includes(el.field)) {
                el.display = false
              }
            })
          } else {
            _this.rulesArr.loadingConfig.rules.forEach((el) => {
              if (el.field === 'loadingType') {
                el.display = true
              }
            })
          }
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          fApi.form.dataAll = val === 'select' ? 1 : fApi.form.dataAll
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val === 1) {
            fApi.form.dataFilter = []
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi, init) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0
          if (rule.props.options.length === 0) {
            rule.props.options = store.state.appDesign.brandCategoryOptions
          }
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },

  'st-enterprise-list-gov': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          _this.rulesArr.templateData.rules[1].display = val !== 'template4'
          _this.rulesArr.templateData.rules[2].display = val === 'template4'
          _this.myValue.loadingConfig.loadingType =
            val === 'template3'
              ? _this.myValue.loadingConfig.loadingType
              : 'none'
          let filterKey = ['loadingType', 'handleType', 'handleText']
          if (val !== 'template3') {
            _this.rulesArr.loadingConfig.rules.forEach((el) => {
              if (filterKey.includes(el.field)) {
                el.display = false
              }
            })
          } else {
            _this.rulesArr.loadingConfig.rules.forEach((el) => {
              if (el.field === 'loadingType') {
                el.display = true
              }
            })
          }
        }
      },
    },
    dataConfig: {
      dataAll: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataShowType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter' && !fApi.form.dataAll
          companyCategoryAll({ data_sources: fApi.form.dataShowType })
            .then((res) => {
              console.log(res.data)
              if (res.data.length !== rule.props.options.length) {
                // 清空之前的所有选项
                rule.value = []
              }
              rule.props.options = res.data
              if (!rule.value.length) {
                rule.value = res.data.map((item) => item.value)
              }
              console.log(rule)
            })
            .catch((err) => {})
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },

  'st-business-association-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {}
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {}
      },

      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi, init) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      platformFilter: function (vm) {
        return (val, rule, fApi, init) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },

  'st-multi-level-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          _this.rulesArr.dataConfig.rules[2].display = val === 'template1'
          _this.rulesArr.templateData.rules[1].display = val !== 'template4'
          _this.rulesArr.templateData.rules[2].display = val === 'template4'
          _this.myValue.loadingConfig.loadingType =
            val === 'template3'
              ? _this.myValue.loadingConfig.loadingType
              : 'none'
          let filterKey = ['loadingType', 'handleType', 'handleText']
          if (!['template3', 'template5', 'template9'].includes(val)) {
            _this.rulesArr.loadingConfig.rules.forEach((el) => {
              if (filterKey.includes(el.field)) {
                el.display = false
              }
            })
          } else {
            _this.rulesArr.loadingConfig.rules.forEach((el) => {
              if (el.field === 'loadingType') {
                el.display = true
              }
            })
          }
        }
      },
    },
    dataConfig: {
      dataAll: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.dataType === 'select'
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },

  'st-activity-photo': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          _this.rulesArr.templateData.rules[3].display = val === 'template3'
          _this.rulesArr.templateData.rules[4].display = val === 'template3'
          _this.rulesArr.templateData.rules[5].display = val === 'template3'
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          fApi.form.dataAll = val === 'select' ? 1 : fApi.form.dataAll
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val === 1) {
            fApi.form.dataFilter = []
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi, init) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0
          if (rule.props.options.length === 0) {
            rule.props.options =
              store.state.appDesign.activityPhotoCategoryOptions
          }
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },

  'st-special-article': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.rule.forEach((el) => {
            if (['listBorderRadius', 'borderRadius'].includes(el.field)) {
              el.display = val === 'template2'
            }
          })
          let filterKey = ['loadingType', 'handleType', 'handleText']
          if (val !== 'template2') {
            _this.rulesArr.loadingConfig.rules.forEach((el) => {
              if (filterKey.includes(el.field)) {
                el.display = false
              }
            })
          } else {
            _this.rulesArr.loadingConfig.rules.forEach((el) => {
              if (el.field === 'loadingType') {
                el.display = true
              }
            })
          }
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          fApi.form.dataAll = val === 'select' ? 1 : fApi.form.dataAll
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val === 1) {
            fApi.form.dataFilter = []
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi, init) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0
          let filterKeys = ['isShowTopic', 'type']
          fApi.rule.forEach((el) => {
            if (filterKeys.includes(el.field)) {
              el.display = fApi.form.dataType === 'filter'
            }
          })
          if (rule.props.options.length === 0) {
            rule.props.options = store.state.appDesign.specialArticleCategory
          }
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
    showConfig: {
      title: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.showType !== 'template3' ? true : false
        }
      },
    },
  },

  'st-live-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    dataConfig: {
      dataAll: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val.length === 0) {
            fApi.form.dataFilter = [101, 102, 103]
          }
          if (val.length === 1) {
            rule.options.forEach((el) => {
              if (el.value === val[0]) {
                el.disabled = true
              }
            })
          } else {
            rule.options.forEach((el) => {
              el.disabled = false
            })
          }
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },

  'st-channels-video-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          fApi.form.dataAll = val === 'select' ? 1 : fApi.form.dataAll
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val === 1) {
            fApi.form.dataFilter = []
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi, init) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0
          if (val.length === 0) {
            fApi.form.dataFilter = []
          }
          if (val.length === 1) {
            rule.options.forEach((el) => {
              if (el.value === val[0]) {
                el.disabled = true
              }
            })
          } else {
            rule.options.forEach((el) => {
              el.disabled = false
            })
          }
          if (rule.props.options.length === 0) {
            allChannelsCategories()
              .then((res) => {
                rule.props.options = res.data
              })
              .catch((err) => {})
          }
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },

  'st-channels-live-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          fApi.form.dataAll = val === 'select' ? 1 : fApi.form.dataAll
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val === 1) {
            fApi.form.dataFilter = []
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi, init) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0
          if (val.length === 0) {
            fApi.form.dataFilter = []
          }
          if (val.length === 1) {
            rule.options.forEach((el) => {
              if (el.value === val[0]) {
                el.disabled = true
              }
            })
          } else {
            rule.options.forEach((el) => {
              el.disabled = false
            })
          }
          if (rule.props.options.length === 0) {
            allChannelsCategories()
              .then((res) => {
                rule.props.options = res.data
              })
              .catch((err) => {})
          }
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },

  'st-spirit-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          _this.rulesArr.templateData.rules.forEach((el) => {
            if (['dataSpacing', 'borderRadius'].includes(el.field)) {
              el.display = ['template3', 'template4'].includes(val)
            }
            if (
              ['showMemberDescription', 'showCompanyDescription'].includes(
                el.field
              )
            ) {
              el.display = ['template1', 'template2'].includes(val)
            }
          })
          _this.rulesArr.loadingConfig.rules.forEach((el) => {
            if (['interactiveType', 'pageLimit'].includes(el.field)) {
              el.display = ['template1', 'template2', 'template5'].includes(val)
            }
          })
        }
      },
    },
    dataConfig: {
      dataAll: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataShowType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      columnFilter: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (rule.options.length === 0) {
            // rule.options = store.state.appDesign.spiritColumnOptions;
            spiritOptions({ data_sources: fApi.form.dataShowType })
              .then((res) => {
                rule.options = res.data.map((item) => {
                  return {
                    label: item.name,
                    value: item.value,
                  }
                })
                if (!rule.value) rule.value = rule.options[0].value
              })
              .catch((err) => {
                console.log(err, 'err')
              })
          }
        }
      },
      categoryFilter: function (vm) {
        let columnFilter = '',
          columnFilterName = ''
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.columnFilter !== ''

          if (fApi.form.columnFilter === columnFilterName) {
            // console.log('不需要重复请求同一个栏目下的分类了')
          }

          if (
            fApi.form.columnFilter !== '' &&
            fApi.form.columnFilter !== columnFilterName
          ) {
            spiritCategoryOptions({
              name: fApi.form.columnFilter,
              data_sources: fApi.form.dataShowType,
            })
              .then((res) => {
                rule.options = res.data

                if (!fApi.form.categoryFilter) {
                  // 栏目下的分类为空
                  fApi.form.categoryFilter = res.data[0].value
                } else if (
                  !res.data.find((rd) => rd.value === fApi.form.categoryFilter)
                ) {
                  // 当前分类与栏目下的所有分类都没有匹配上，则默认第一个
                  fApi.form.categoryFilter = res.data[0].value
                }

                columnFilterName = fApi.form.columnFilter
              })
              .catch((err) => {
                console.log(err, 'err')
              })
          }
          columnFilter = fApi.form.columnFilter
        }
      },
    },
    loadingConfig: {
      interactiveType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.rule[4].display =
            val === 'swiper' &&
            ['template1', 'template2', 'template5'].includes(
              _this.myValue.templateData.styleType
            )
              ? true
              : false
        }
      },
      autoPlay: function (vm) {
        return (val, rule, fApi) => {
          console.log(fApi)
          const _this = vm
          fApi.rule[2].display =
            fApi.form.interactiveType === 'swiper' &&
            ['template1', 'template2', 'template5'].includes(
              _this.myValue.templateData.styleType
            )
              ? true
              : false
        }
      },
      swiperTime: function (vm) {
        return (val, rule, fApi) => {
          console.log(fApi)
          const _this = vm
          fApi.rule[4].display =
            fApi.form.interactiveType === 'swiper' &&
            ['template1', 'template2', 'template5'].includes(
              _this.myValue.templateData.styleType
            )
              ? true
              : false
        }
      },
    },
  },

  'st-activity-list-deprecated': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi, init) => {
          const _this = vm

          if (val === 'template2') {
            _this.myValue.templateData.styleType =
              'template1' /* 模板2已废弃，兼容至模板1 */
          }

          // _this.rulesArr.showConfig.isShow = val !== "template1" ? 1 : 0;
          _this.myValue.loadingConfig.loadingType =
            val !== 'template4'
              ? _this.myValue.loadingConfig.loadingType
              : 'none'
          let filterKey = ['loadingType', 'handleType', 'handleText']
          if (val === 'template4') {
            _this.rulesArr.loadingConfig.rules.forEach((el) => {
              if (filterKey.includes(el.field)) {
                el.display = false
              }
            })
          } else {
            _this.rulesArr.loadingConfig.rules.forEach((el) => {
              if (el.field === 'loadingType') {
                el.display = true
              }
            })
          }

          if (val === 'template2' || val === 'template4') {
            if (!init) {
              // 模板二默认图片在右边
              _this.myValue.listStyle.imageText =
                val === 'template2' ? 'right' : 'left'
              _this.myValue.templateData.borderRadius = 16
            } else if (init) {
              if (!_this.rulesArr.listStyle.rules[0].value) {
                _this.myValue.listStyle.imageText =
                  val === 'template2' ? 'right' : 'left'
              }
            }

            // 支持设置图片在左或者在右
            // _this.rulesArr.listStyle.isShow = 1
            _this.rulesArr.listStyle.rules[0].display = true
          } else {
            // _this.rulesArr.listStyle.isShow = 0
            _this.rulesArr.listStyle.rules[0].display = false
          }

          if (!init && val === 'template3') {
            _this.myValue.templateData.borderRadius = 12
          }

          if (!init && val === 'template1') {
            _this.myValue.templateData.borderRadius = 16
          }
        }
      },
    },
    dataConfig: {
      dataAll: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataShowType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val.length === 0) {
            fApi.form.dataFilter = [0, 1, 2]
          }
          if (val.length === 1) {
            rule.options.forEach((el) => {
              if (el.value === val[0]) {
                el.disabled = true
              }
            })
          } else {
            rule.options.forEach((el) => {
              el.disabled = false
            })
          }
        }
      },
      category_names: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter' && !fApi.form.dataAll

          allCategories({ data_sources: fApi.form.dataShowType })
            .then((res) => {
              rule.options = res.data
              if (!rule.value.length) {
                rule.value = res.data.map((item) => item.value)
              }
            })
            .catch((err) => {})
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
    listStyle: {
      imageText: function (vm) {
        return (val, rule, fApi, init) => {
          // const _this = vm;
          // const styleType = vm.rulesArr.templateData.rules[0].value;
          // if (styleType === 'template2' || styleType === 'template4') {
          //   console.log('init', init, val)
          //
          //   if (!init && styleType === 'template2') {
          //     // 模板二默认图片在右边
          //    rule.value = 'right'
          //   } else if (init && styleType === 'template2') {
          //     if (!rule.value) {
          //       rule.value = 'right'
          //     }
          //   }
          //
          //   // 支持设置图片在左或者在右
          //   _this.rulesArr.listStyle.isShow = 1;
          // } else {
          //   _this.rulesArr.listStyle.isShow = 0
          // }
          // console.log(styleType,  _this.rulesArr.listStyle.isShow)
        }
      },
    },
  },
  'st-donation-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
    dataConfig: {
      dataAll: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      projectStatus: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val.length === 0) {
            fApi.form.dataFilter = [0, 1, 2]
          }
          if (val.length === 1) {
            rule.options.forEach((el) => {
              if (el.value === val[0]) {
                el.disabled = true
              }
            })
          } else {
            rule.options.forEach((el) => {
              el.disabled = false
            })
          }
        }
      },
    },
  },

  'st-activity-list-gov': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // _this.rulesArr.showConfig.isShow = val !== "template1" ? 1 : 0;
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },

  'st-article-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (_this) {
        return (val, rule, fApi, init = false) => {
          if (!init) {
            /**
             * 初始化时不要触发以下代码
             */
            if (val === 'template1') {
              fApi.form.borderRadius = 4
              fApi.form.dataSpacing = 15
              _this.rulesArr.listStyle.rules[1].value = 'left'
              _this.rulesArr.listStyle.rules[4].display = false
              _this.rulesArr.listStyle.rules[4].value = 'unset'
            } else if (val === 'template2') {
              fApi.form.borderRadius = 6
              fApi.form.dataSpacing = 12
              _this.rulesArr.listStyle.rules[1].value = 'right'
              _this.rulesArr.listStyle.rules[4].display = true
              _this.rulesArr.listStyle.rules[4].value = '#f7f8f9'
            }
          }
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi, init = false) => {
          if (!init) {
            if (val === 'select') {
              fApi.form.dataAll = 1
              fApi.form.dataFilter = []
              fApi.form.source_all = 1
              fApi.form.source_ids = []
            }
          }
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi, init = false) => {
          rule.display = fApi.form.dataType === 'filter'
          if (!init) {
            if (val === 1) {
              fApi.form.dataFilter = []
              fApi.form.source_ids = []
            }
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataShowType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi, init = false) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0
          let filterKeys = ['isShowTopic', 'type']
          fApi.rule.forEach((el) => {
            if (filterKeys.includes(el.field)) {
              el.display = fApi.form.dataType === 'filter'
            }
          })
          articleCategoryAll({ data_sources: fApi.form.dataShowType })
            .then((res) => {
              // console.log(
              //   res.data,
              //   rule.value,
              //   '123123123123',
              //   res.data.length !== rule.props.options.length,
              //   rule.value.length
              // )
              // if (res.data.length !== rule.props.options.length) {
              //   // 清空之前的所有选项
              //   rule.value = []
              // }
              rule.props.options = res.data
              if (!rule.value.length) {
                rule.value = res.data.map((item) => item.value)
              }
              console.log(rule)
            })
            .catch((err) => {})
        }
      },
      source_all: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      source_ids: function (vm) {
        return (val, rule, fApi, init = false) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          // 大的过滤开关控制
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0 && fApi.form.source_all === 0

          // 这一堆不知道能不能删！
          let filterKeys = ['isShowTopic', 'type']
          fApi.rule.forEach((el) => {
            if (filterKeys.includes(el.field)) {
              el.display = fApi.form.dataType === 'filter'
            }
          })

          if (init) {
            // 来源选项数据
            getAllSources({  })
                .then((res) => {
                  rule.props.options = res.data
                  if (!rule.value.length) {
                    rule.value = res.data.map((item) => item.id) // 默认选中所有来源
                  }
                })
                .catch((err) => {})
          } else {
            if (!rule.value.length) {
              rule.value = rule.props.options.map((item) => item.id) // 默认选中所有来源
            }
          }
        }
      },
      topicType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const type = fApi.form.type
          rule.display =
            fApi.form.isShowTopic === 1 && fApi.form.dataType === 'filter'
          if (fApi.form.dataType === 'filter') {
            if (val) {
              _this.rulesArr.listStyle.rules[0].display =
                val === 0 || !!type.includes(0)
              _this.rulesArr.listStyle.rules[1].display =
                val === 1 || !!type.includes(1)
              _this.rulesArr.listStyle.rules[2].display =
                val === 2 || !!type.includes(2)
              _this.rulesArr.listStyle.rules[3].display =
                val === 3 || !!type.includes(3)
            } else {
              console.log('update.js st-article-list normal.type val', val)
            }
          } else {
            _this.rulesArr.listStyle.rules.forEach((el) => {
              el.display = true
            })
          }
        }
      },
      type: function (vm) {
        return (val, rule, fApi, init) => {
          const _this = vm
          const topicType = fApi.form.topicType
          if (fApi.form.dataType === 'filter') {
            if (val) {
              _this.rulesArr.listStyle.isShow = val?.length ? 1 : 0
              _this.rulesArr.listStyle.rules[0].display =
                !!val.includes(0) || topicType === 0
              _this.rulesArr.listStyle.rules[1].display =
                !!val.includes(1) || topicType === 1
              _this.rulesArr.listStyle.rules[2].display =
                !!val.includes(2) || topicType === 2
              _this.rulesArr.listStyle.rules[3].display =
                !!val.includes(3) || topicType === 3
              if (!val.length) {
                fApi.form.type = init ? [0, 1, 2, 3] : [1]
              }
            } else {
              console.log('update.js st-article-list normal.type val', val)
            }
          } else {
            _this.rulesArr.listStyle.rules.forEach((el) => {
              el.display = true
            })
          }
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },

  'st-custom-header-old': {
    templateData: {
      template: function (vm) {
        return (val, rule, fApi, init = false) => {
          const _this = vm
          // _this.rulesArr.searchConfig.rules[5].display = val === 'template1';
          // _this.rulesArr.searchConfig.rules[6].display = val === 'template1';
          // 模板6去除掉视频类型数据
          if (val === 'template6') {
            _this.rulesArr.dataConfig.rules[4].props.pageData.banner_types = [
              0, 2,
            ]

            _this.rulesArr.dataConfig.rules[3].value = [0, 2]
            _this.rulesArr.dataConfig.rules[3].props.options[2].disabled = true
            if (!init) {
              _this.rulesArr.dataConfig.rules[4].value = []
            }
          } else {
            _this.rulesArr.dataConfig.rules[4].props.pageData.banner_types = [
              0, 2, 3,
            ]
            _this.rulesArr.dataConfig.rules[3].value = [0, 2, 3]
            _this.rulesArr.dataConfig.rules[3].props.options[2].disabled = false
          }
          _this.rulesArr.contentConfig.isShow =
            val === 'template1' || val === 'template5' || val === 'template6'
              ? 1
              : 0
          // _this.rulesArr.upContentConfig.isShow = val == 'template1' ? 1 : 0;

          _this.rulesArr.pageJump.isShow = val === 'template1' ? 0 : 1

          _this.rulesArr.searchConfigTemplate1.isShow =
            val === 'template1' || val === 'template5' || val === 'template6'
              ? 1
              : 0
          if (!init) {
            /**
             * 初始化时不要触发以下代码
             */
            if (['template5', 'template6'].includes(val)) {
              _this.rulesArr.searchConfigTemplate1.rules.find(
                (el) => el.field === 'searchColor'
              ).value = 'rgba(255, 255, 255, 1)'
              _this.rulesArr.searchConfigTemplate1.rules.find(
                (el) => el.field === 'placeholderColor'
              ).value = '#b3b3b3'
              _this.rulesArr.searchConfigTemplate1.rules.find(
                (el) => el.field === 'placeholder'
              ).value = ''
            } else {
              _this.rulesArr.searchConfigTemplate1.rules.find(
                (el) => el.field === 'searchColor'
              ).value = 'rgba(243,244,245,1)'
              _this.rulesArr.searchConfigTemplate1.rules.find(
                (el) => el.field === 'placeholderColor'
              ).value = '#979da6'
            }
          }

          _this.rulesArr.bannerSearchConfig.isShow = [
            'template2',
            'template4',
          ].includes(val)
            ? 1
            : 0
          _this.rulesArr.bannerSearchConfig.rules.find(
            (el) => el.field === 'template2_searchType'
          ).display = val === 'template2'
          _this.rulesArr.bannerSearchConfig.rules.find(
            (el) => el.field === 'template4_searchType'
          ).display = val === 'template4'

          let dataTemplateList1 = [
            'template2',
            'template3',
            'template4',
            'template6',
          ]
          let dataTemplateList2 = ['template2', 'template3', 'template4']

          _this.rulesArr.dataConfig.isShow = dataTemplateList1.includes(val)
            ? 1
            : 0
          _this.rulesArr.loadingConfig.isShow = dataTemplateList2.includes(val)
            ? 1
            : 0

          /* 只有模板一有政务相关配置 */
          _this.rulesArr.gov.isShow = val === 'template1' ? 1 : 0

          _this.rulesArr.contentConfig.rules.forEach((el) => {
            let bol = true
            if (
              ['template5', 'template6'].includes(el.field) ||
              ['template5', 'template6'].includes(val)
            ) {
              if (val !== el.field) bol = false
            }
            if (el.field === 'showSearch') {
              if (val !== 'template1') bol = false
            }
            el.display = bol
          })
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi, init = false) => {
          console.log(
            val,
            rule,
            fApi,
            (init = false),
            'val, rule, fApi, init = false'
          )
          if (!init) {
            if (val === 'select') {
              fApi.form.dataAll = 1
              fApi.form.dataFilter = []
            }
          }
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi, init = false) => {
          rule.display = fApi.form.dataType === 'filter'
          if (!init) {
            if (val === 1) {
              fApi.form.dataFilter = []
            }
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi, init = false) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0

          // fApi.rule.forEach((el) => {
          //   if (filterKeys.includes(el.field)) {
          //     el.display = fApi.form.dataType === 'filter'
          //   }
          // })
          bannerCategoryAll({})
            .then((res) => {
              // console.log(
              //   res.data,
              //   rule.value,
              //   '123123123123',
              //   res.data.length !== rule.props.options.length,
              //   rule.value.length
              // )
              // if (res.data.length !== rule.props.options.length) {
              //   // 清空之前的所有选项
              //   rule.value = []
              // }
              rule.props.options = res.data
              if (!rule.value.length) {
                rule.value = res.data.map((item) => item.id)
              }
            })
            .catch((err) => {})
        }
      },
      type: function (vm) {
        return (val, rule, fApi, init) => {
          rule.display = fApi.form.dataType === 'filter'
          if (fApi.form.dataType === 'filter') {
            if (val) {
              if (!val.length) {
                fApi.form.type = init ? [0, 2, 3] : [0]
              }
            }
          }
        }
      },
    },
    contentConfig: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const filterKey = ['fontWeight', 'fontSize', 'fontColor']
          fApi.rule.forEach((el) => {
            if (filterKey.includes(el.field)) {
              el.display =
                val.isShow === 1 &&
                vm.rulesArr.templateData.rules[0].value !== 'template5'
            }
          })
        }
      },
    },
    upContentConfig: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const filterKey = ['fontWeight', 'fontSize', 'fontColor']
          fApi.rule.forEach((el) => {
            if (filterKey.includes(el.field)) {
              el.display = val.isShow === 1 && fApi.form.showType === 'text'
            }
          })
        }
      },
      showType: function () {
        return (val, rule, fApi) => {
          const filterKey = [
            'titleConfig',
            'fontWeight',
            'fontSize',
            'fontColor',
          ]
          fApi.rule.forEach((el) => {
            if (filterKey.includes(el.field)) {
              el.display = val === 'text' && fApi.form.titleConfig.isShow === 1
            }
          })
          const filterSearchKey = ['searchColor', 'placeholderColor']
          fApi.rule.forEach((el) => {
            if (filterSearchKey.includes(el.field)) {
              el.display = val === 'search'
            }
          })
        }
      },
    },
    searchConfig: {
      searchContentType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          if (rule.options.length === 0) {
            rule.options = store.state.appDesign.searchTypes
          }
          const data = []
          if (!val.length) {
            rule.options.forEach((el) => {
              data.push(el.value)
            })
            fApi.form.searchContentType = data
          } else if (val.length === 1) {
            rule.options.forEach((el) => {
              if (el.value === val[0]) {
                el.disabled = true
              }
            })
          } else if (val.length > 1) {
            rule.options.forEach((el) => {
              el.disabled = false
              if (fApi.form.searchContentType?.includes(el.value)) {
                data.push(el.value)
              }
            })
            fApi.form.searchContentType = data
          }
        }
      },
    },
    gov: {
      isEnableOthers: function () {
        return (val, rule, fApi) => {
          fApi.rule.forEach((el) => {
            if (el.field !== 'isEnableOthers') el.display = val === 1
          })
        }
      },
      filterAreaType: function () {
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.isFilterArea === 1 && fApi.form.isEnableOthers === 1
        }
      },
    },
  },

  'st-banner': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      rowPadding: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.styleType === 'template2' ||
            fApi.form.styleType === 'template4'
              ? true
              : false
        }
      },
      indicatorType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.styleType === 'template2' ? true : false
        }
      },
      playType: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template2'
        }
      },
      relative: function () {
        return (val, rule, fApi) => {
          // rule.display = fApi.form.styleType === "template1";
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi, init = false) => {
          console.log(
            val,
            rule,
            fApi,
            (init = false),
            'val, rule, fApi, init = false'
          )
          if (!init) {
            if (val === 'select') {
              fApi.form.dataAll = 1
              fApi.form.dataFilter = []
            }
          }
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi, init = false) => {
          rule.display = fApi.form.dataType === 'filter'
          if (!init) {
            if (val === 1) {
              fApi.form.dataFilter = []
            }
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi, init = false) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0

          // fApi.rule.forEach((el) => {
          //   if (filterKeys.includes(el.field)) {
          //     el.display = fApi.form.dataType === 'filter'
          //   }
          // })
          bannerCategoryAll({})
            .then((res) => {
              // console.log(
              //   res.data,
              //   rule.value,
              //   '123123123123',
              //   res.data.length !== rule.props.options.length,
              //   rule.value.length
              // )
              // if (res.data.length !== rule.props.options.length) {
              //   // 清空之前的所有选项
              //   rule.value = []
              // }
              rule.props.options = res.data
              if (!rule.value.length) {
                rule.value = res.data.map((item) => item.id)
              }
            })
            .catch((err) => {})
        }
      },
      type: function (vm) {
        return (val, rule, fApi, init) => {
          rule.display = fApi.form.dataType === 'filter'
          if (fApi.form.dataType === 'filter') {
            if (val) {
              if (!val.length) {
                fApi.form.type = init ? [0, 2, 3] : [0]
              }
            }
          }
        }
      },
    },
  },

  'st-search': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
      searchType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          if (rule.options.length === 0) {
            // 如果是商城系统页的搜索组件，搜索类型只支持 商城类型
            if (
              store.state.appDesign.pageDetail.system_page === 'shop_index' ||
              store.state.appDesign.pageDetail.system_page ===
                'integral_shop_index'
            ) {
              console.log(store.state.appDesign.searchTypes)
              rule.options = store.state.appDesign.searchTypes.filter(
                (item) => item.value === 'shop'
              )
            } else {
              rule.options = store.state.appDesign.searchTypes
            }
          }

          const data = []
          if (!val.length) {
            rule.options.forEach((el) => {
              data.push(el.value)
            })
            fApi.form.searchType = data
          } else if (val.length === 1) {
            rule.options.forEach((el) => {
              if (el.value === val[0]) {
                el.disabled = true
              }
            })
          } else if (val.length > 1) {
            rule.options.forEach((el) => {
              el.disabled = false
            })
            // 需要根据用户选择的顺序来
            fApi.form.searchType.forEach((s) => {
              if (rule.options.find((op) => op.value === s)) {
                data.push(s)
              }
            })
            fApi.form.searchType = data
          }
        }
      },
      searchRadius: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.shapeType === 'square'
        }
      },
    },
  },

  'st-image-text-nav': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image'
        }
      },
    },
    templateData: {
      // styleType: function() {
      //   return (val, rule, fApi) => {
      //   }
      // },
      showType: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template1'
        }
      },
      template4StyleType: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template4'
        }
      },
      rowLimit: function () {
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.showType === 'swiper' &&
            fApi.form.styleType === 'template1'
        }
      },
      numLimit: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template1'
        }
      },
      textColor: function () {
        return (val, rule, fApi) => {
          rule.display = ['template1', 'template3'].includes(
            fApi.form.styleType
          )
        }
      },
      textSpacing: function () {
        return (val, rule, fApi) => {
          rule.display = ['template1', 'template3'].includes(
            fApi.form.styleType
          )
        }
      },
      // borderRadiusConfig: function () {
      //   return (val, rule, fApi) => {
      //     rule.display = fApi.form.styleType === 'template2';
      //   }
      // },
      borderRadius: function () {
        return (val, rule, fApi) => {
          // rule.display = fApi.form.styleType === 'template2' // 2024年08月22日11:19:46 改为都支持该属性配置
        }
      },
      dataSpacing: function () {
        return (val, rule, fApi) => {
          rule.display = ['template2', 'template3', 'template4'].includes(
            fApi.form.styleType
          )
        }
      },
      iconSize: function () {
        return (val, rule, fApi) => {
          rule.display = ['template1', 'template3'].includes(
            fApi.form.styleType
          )
        }
      },
      showText: function () {
        return (val, rule, fApi) => {
          rule.display = ['template1', 'template3'].includes(
            fApi.form.styleType
          )
          fApi.rule.forEach((r) => {
            if (['textColor', 'textSpacing', 'fontWeight'].includes(r.field)) {
              r.display =
                ['template1', 'template3'].includes(fApi.form.styleType) &&
                val === 1
            }
          })
        }
      },
    },
  },
  'st-column-navigation': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image'
        }
      },
    },
  },
  'st-shop-categories': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image'
        }
      },
    },
    templateData: {
      // styleType: function() {
      //   return (val, rule, fApi) => {
      //   }
      // },
      showType: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template1'
        }
      },
      rowLimit: function () {
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.showType === 'swiper' &&
            fApi.form.styleType === 'template1'
        }
      },
      numLimit: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template1'
        }
      },
      textColor: function () {
        return (val, rule, fApi) => {
          rule.display = ['template1', 'template3'].includes(
            fApi.form.styleType
          )
        }
      },
      textSpacing: function () {
        return (val, rule, fApi) => {
          rule.display = ['template1', 'template3'].includes(
            fApi.form.styleType
          )
        }
      },
      // borderRadiusConfig: function () {
      //   return (val, rule, fApi) => {
      //     rule.display = fApi.form.styleType === 'template2';
      //   }
      // },
      borderRadius: function () {
        return (val, rule, fApi) => {
          // rule.display = fApi.form.styleType === 'template2'
        }
      },
      dataSpacing: function () {
        return (val, rule, fApi) => {
          rule.display = ['template2', 'template3'].includes(
            fApi.form.styleType
          )
        }
      },
    },
  },

  'st-theme': {
    normal: {
      theme: function () {
        return (val, rule, fApi) => {
          rule.display = !fApi.form.isCustom
        }
      },
      mainColor: function () {
        return (val, rule, fApi) => {
          rule.display = !!fApi.form.isCustom
        }
      },
      helpColor: function () {
        return (val, rule, fApi) => {
          rule.display = !!fApi.form.isCustom
        }
      },
    },
    otherConfig: {
      isGray: function () {
        return (val, rule, fApi) => {
          fApi.rule[1].display = val === 1
        }
      },
      style: function () {
        return (val, rule, fApi) => {
          rule.display = val !== 'default'
        }
      },
    },
  },

  'st-shop-goods': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
        }
      },
      buttonTemplate: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // 开启购买按钮
          rule.display = fApi.form.ShowPayBtn === 1
        }
      },
      ShowPayBtn: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
        }
      },
      showAddBorder: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // 模板1 才显示
          rule.display = fApi.form.styleType === 'template1'
        }
      },

      photoSize: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          console.log(_this.rulesArr.templateData, rule)
          if (
            ['template1'].includes(_this.rulesArr.templateData.rules[0].value)
          ) {
            rule.props.options = [
              {
                name: '默认',
                value: 'template1',
              },
              {
                name: '4:3',
                value: 'template2',
              },
            ]
            // 图片尺寸默认选择回模板一
            if (!['template1', 'template2'].includes(rule.value)) {
              rule.value = 'template1'
            }
          }
          if (
            ['template2', 'template3'].includes(
              _this.rulesArr.templateData.rules[0].value
            )
          ) {
            rule.props.options = [
              {
                name: '默认',
                value: 'template1',
              },
              {
                name: '1:1',
                value: 'template3',
              },
            ]
            // 图片尺寸默认选择回模板一
            if (!['template1', 'template3'].includes(rule.value)) {
              rule.value = 'template1'
            }
          }
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          fApi.form.dataAll = val === 'select' ? 1 : fApi.form.dataAll
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val === 1) {
            fApi.form.dataFilter = []
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataShowType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      dataMold: function (vm) {
        return (val, rule, fApi) => {
          const systemPageName =
            vm.$store.state.appDesign.pageDetail?.system_page
          if (systemPageName === 'integral_shop_index') fApi.form.dataMold = 1

          rule.display =
            fApi.form.dataType === 'filter' &&
            systemPageName !== 'integral_shop_index'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi, init) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0
          shopCategoryAll({ data_sources: fApi.form.dataShowType })
            .then((res) => {
              console.log(res.data)
              if (res.data.length !== rule.props.options.length) {
                // 清空之前的所有选项
                // rule.value = []
              }
              rule.props.options = res.data
              if (!rule.value.length) {
                rule.value = res.data.map((item) => item.value)
              }
              console.log(rule)
            })
            .catch((err) => {})
        }
      },
      pay_type: function (vm) {
        return (val, rule, fApi) => {
          // const systemPageName = vm.$store.state.appDesign.pageDetail?.system_page
          // rule.display = systemPageName === 'integral_shop_index' && fApi.form.dataMold === 1
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },
  'st-shop-category-page': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
        }
      },
      template: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // 商城模板为 1 才显示
          rule.display = fApi.form.styleType === 'template1'
          console.log(rule.display)
        }
      },
      buttonTemplate: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // 开启购买按钮
          rule.display = fApi.form.ShowPayBtn === 1
        }
      },
      ShowPayBtn: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
        }
      },
      showAddBorder: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // 模板1 才显示
          rule.display =
            fApi.form.template === 'template1' &&
            fApi.form.styleType === 'template1'
        }
      },

      photoSize: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          console.log(_this.rulesArr.templateData, rule)
          // 如果是商城分类模板二的话只有 默认 和 1:1
          if (fApi.form.styleType === 'template2') {
            console.log(1)
            rule.props.options = [
              {
                name: '默认',
                value: 'template1',
              },
              {
                name: '1:1',
                value: 'template3',
              },
            ]
            // 图片尺寸 如果是 4:3的话 默认选择回默认
            if (!['template1', 'template3'].includes(rule.value)) {
              rule.value = 'template1'
            }
            return
          }
          if (['template1'].includes(fApi.form.template)) {
            console.log(2)
            rule.props.options = [
              {
                name: '默认',
                value: 'template1',
              },
              {
                name: '4:3',
                value: 'template2',
              },
            ]
            // 图片尺寸默认选择回模板一
            if (!['template1', 'template2'].includes(rule.value)) {
              rule.value = 'template1'
            }
          }
          if (['template2', 'template3'].includes(fApi.form.template)) {
            console.log(3)
            rule.props.options = [
              {
                name: '默认',
                value: 'template1',
              },
              {
                name: '1:1',
                value: 'template3',
              },
            ]
            // 图片尺寸默认选择回模板一
            if (!['template1', 'template3'].includes(rule.value)) {
              rule.value = 'template1'
            }
          }
        }
      },
    },
  },

  'st-official-account': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
  },

  'st-me-page': {
    header: {
      template: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.form.height =
            fApi.form.template === 'circle'
              ? 100
              : fApi.form.template === 'square'
              ? 100
              : fApi.form.height
          // fApi.form.radian = fApi.form.template === 'circle' ? 70 : fApi.form.template === 'square' ? 0 : fApi.form.radian
          fApi.form.radian = fApi.form.template === 'circle' ? 70 : 0

          _this.rulesArr.consultBtn.isShow =
            store.state.tenant.features.includes('consult') ? 1 : 0
        }
      },
      height: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.template === 'custom' ? true : false
        }
      },
      radian: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.template === 'custom' ? true : false
          // rule.display = false
        }
      },
      opacity: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.template === 'custom' ? true : false
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    messageConfig: {
      positionMultiple: function () {
        return (val, rule, fApi) => {
          rule.display = !!fApi.form.showPosition
          fApi.rule.forEach((el) => {
            if (el.field === 'positionOrder') {
              el.display = val === 1 && rule.display
            }
          })
        }
      },
      quickEnterOne: function (vm) {
        return (val, rule, fApi) => {
          // TODO: 封装实时获取下拉选项的 helper
          rule.props.options = JSON.parse(
            JSON.stringify(store.state.appDesign.mePageLinkOptions)
          )
          if (rule.props.options.length === 0) {
            Vue.bus.on('form-update', ({ key, val }) => {
              if (key !== 'mePageLinkOptions') return
              rule.props.options = val
            })
          }
          rule.props.options.map((el) => {
            if (
              [
                fApi.form.quickEnterTwo.value,
                fApi.form.quickEnterThree.value,
                fApi.form.quickEnterFour.value,
              ].includes(el.value) &&
              el.value !== ''
            ) {
              el.disabled = true
            } else {
              el.disabled = false
            }
          })
        }
      },
      quickEnterTwo: function (vm) {
        return (val, rule, fApi) => {
          rule.props.options = JSON.parse(
            JSON.stringify(store.state.appDesign.mePageLinkOptions)
          )
          if (rule.props.options.length === 0) {
            Vue.bus.on('form-update', ({ key, val }) => {
              if (key !== 'mePageLinkOptions') return
              rule.props.options = val
            })
          }
          rule.props.options.map((el) => {
            if (
              [
                fApi.form.quickEnterOne.value,
                fApi.form.quickEnterThree.value,
                fApi.form.quickEnterFour.value,
              ].includes(el.value) &&
              el.value !== ''
            ) {
              el.disabled = true
            } else {
              el.disabled = false
            }
          })
        }
      },
      quickEnterThree: function (vm) {
        return (val, rule, fApi) => {
          rule.props.options = JSON.parse(
            JSON.stringify(store.state.appDesign.mePageLinkOptions)
          )
          if (rule.props.options.length === 0) {
            Vue.bus.on('form-update', ({ key, val }) => {
              if (key !== 'mePageLinkOptions') return
              rule.props.options = val
            })
          }
          rule.props.options.map((el) => {
            if (
              [
                fApi.form.quickEnterOne.value,
                fApi.form.quickEnterTwo.value,
                fApi.form.quickEnterFour.value,
              ].includes(el.value) &&
              el.value !== ''
            ) {
              el.disabled = true
            } else {
              el.disabled = false
            }
          })
        }
      },
      quickEnterFour: function (vm) {
        return (val, rule, fApi) => {
          rule.props.options = JSON.parse(
            JSON.stringify(store.state.appDesign.mePageLinkOptions)
          )
          if (rule.props.options.length === 0) {
            Vue.bus.on('form-update', ({ key, val }) => {
              if (key !== 'mePageLinkOptions') return
              rule.props.options = val
            })
          }
          rule.props.options.map((el) => {
            if (
              [
                fApi.form.quickEnterOne.value,
                fApi.form.quickEnterTwo.value,
                fApi.form.quickEnterThree.value,
              ].includes(el.value) &&
              el.value !== ''
            ) {
              el.disabled = true
            } else {
              el.disabled = false
            }
          })
        }
      },
    },
    serviceBtn: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          vm.rulesArr.serviceBtn.isShow =
            vm.$route.params?.platform === 'mp-weixin' ? 1 : 0
        }
      },
    },
  },

  'st-user-center-nav': {
    normal: {
      numLimit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.showType === 'special'
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
  },
  'st-default-user-center-nav': {
    normal: {
      numLimit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.showType === 'special'
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
  },
  // 联系我插件
  'st-contact-me': {
    normal: {
      configId: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          let oldConfigType = -1
          if (oldConfigType !== val.configType) {
            if (val.configType === 1) {
              _this.rulesArr.templateData.rules[0].props.options = [
                { name: '模板1', value: 1 },
                { name: '模板2', value: 2 },
                { name: '模板3', value: 3 },
              ]
              if (
                typeof _this.rulesArr.templateData.rules[0].update ===
                'function'
              ) {
                _this.rulesArr.templateData.rules[0].update(
                  _this.value.templateData.styleType
                )
              }
            } else if (val.configType === 2) {
              _this.rulesArr.templateData.rules[0].props.options = [
                { name: '模板1', value: 1 },
                { name: '模板2', value: 2 },
              ]
              if (
                typeof _this.rulesArr.templateData.rules[0].update ===
                'function'
              ) {
                _this.rulesArr.templateData.rules[0].update(
                  _this.value.templateData.styleType
                )
              }
            } else {
              _this.rulesArr.templateData.rules.map(
                (el) => (el.display = false)
              )
            }
          }
          oldConfigType = val.configType
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          let configType = _this.value.normal.configId.configType
          if (!configType) return
          switch (val) {
            case 1:
              const filterKey1 = ['serviceText', 'showPosition', 'btnColor2']
              _this.fApi.rule.forEach((el) => {
                el.display = filterKey1.includes(el.field) ? false : true
              })
              break
            case 2:
              const filterKey2 = [
                'contactText',
                'serviceText',
                'isMask',
                'buttonText',
                'btnColor1',
                'rowMargin',
              ]
              _this.fApi.rule.forEach((el) => {
                el.display = filterKey2.includes(el.field) ? false : true
              })
              break
            case 3:
              const unfilterKey = [
                'styleType',
                'contactText',
                'serviceText',
                'rowMargin',
              ]
              _this.fApi.rule.forEach((el) => {
                el.display = unfilterKey.includes(el.field) ? true : false
              })
              break
          }
        }
      },
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          let configType = _this.value.normal.configId.configType
          if (!configType) return
          const filterKey = ['fontWeight', 'fontSize', 'fontColor']
          fApi.rule.forEach((el) => {
            if (filterKey.includes(el.field)) {
              el.display = val.isShow === 1 && fApi.form.styleType !== 3
            }
          })
        }
      },
      isMask: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          let configType = _this.value.normal.configId.configType
          if (!configType) return
          fApi.rule.forEach((el) => {
            if (el.field === 'contactText') {
              el.display =
                (val === 1 && fApi.form.styleType === 1) ||
                fApi.form.styleType === 2
                  ? false
                  : true
            }
          })
        }
      },
    },
  },

  // 自由容器
  'st-free-container': {
    normal: {
      bgConfig: function (vm) {
        // "imgPosition"
        return (val, rule, fApi) => {
          const _this = vm
          const filterKey = [
            'styleType',
            'bgImg',
            'imgFit',
            'tileType',
            'imgPosition',
            'bgColor',
            'opacity',
            '',
          ]
          fApi.rule.forEach((el) => {
            if (filterKey.includes(el.field)) {
              el.display = val === 'custom'
              // const styleTypeFilter = ["bgImg", "imgFit", "tileType"]
              // if (styleTypeFilter.includes(el.field)) {
              //   el.display = fApi.form.styleType === 'image' && val === 'custom'
              //   const bgImgFilter = ["imgFit", "tileType"]
              //   if (bgImgFilter.includes(el.field)) {
              //     el.display = fApi.form.bgImg !== '' && val === 'custom' && fApi.form.styleType === 'image'
              //     if (el.field === 'tileType') {
              //       el.display = fApi.form.imgFit === 'repeat' && val === 'custom' && fApi.form.styleType === 'image' && fApi.form.bgImg !== ''
              //     }
              //   }
              // }
            }
          })
        }
      },
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const filterKey = ['bgImg', 'imgFit', 'tileType', 'imgPosition']
          fApi.rule.forEach((el) => {
            if (filterKey.includes(el.field)) {
              el.display = val === 'image' && fApi.form.bgConfig === 'custom'
            }
          })
        }
      },
      bgImg: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const filterKey = ['imgFit', 'tileType', 'imgPosition']
          fApi.rule.forEach((el) => {
            if (filterKey.includes(el.field)) {
              el.display =
                val !== '' &&
                fApi.form.bgConfig === 'custom' &&
                fApi.form.styleType === 'image'
            }
          })
        }
      },
      imgFit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.rule.forEach((el) => {
            if (el.field === 'tileType') {
              el.display =
                val === 'repeat' &&
                fApi.form.bgConfig === 'custom' &&
                fApi.form.styleType === 'image' &&
                fApi.form.bgImg !== ''
            }
            if (el.field === 'imgPosition') {
              el.display =
                val === 'origin' &&
                fApi.form.bgConfig === 'custom' &&
                fApi.form.styleType === 'image' &&
                fApi.form.bgImg !== ''
            }
          })
        }
      },
    },
  },

  'st-text': {
    gov: {
      isEnableOthers: function () {
        return (val, rule, fApi) => {
          fApi.rule.forEach((el) => {
            if (el.field !== 'isEnableOthers') el.display = val === 1
          })
        }
      },
      filterAreaType: function () {
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.isFilterArea === 1 && fApi.form.isEnableOthers === 1
        }
      },
    },
  },

  'st-company-contact': {
    normal: {
      titleConfig: function (vm) {
        let getable = true
        return (val, rule, fApi) => {
          const _this = vm
          fApi.rule.forEach((el) => {
            if (['fontWeight', 'fontColor'].includes(el.field)) {
              el.display = val.isShow === 1
            }
          })
          if (getable) {
            getPluginStatus()
              .then((res) => {
                const pluginData = res.data
                _this.rulesArr.contactMeConfig.disabled =
                  pluginData.contact_status === 0
                _this.rulesArr.joinChatConfig.disabled =
                  pluginData.group_status === 0
                _this.rulesArr.contactMeConfig.rules.forEach((el) => {
                  if (
                    [
                      'ID',
                      'btnText',
                      'fontWeight',
                      'fontColor',
                      'fontSize',
                    ].includes(el.field)
                  ) {
                    el.display = !_this.rulesArr.contactMeConfig.disabled
                  } else if (el.field === 'isOpen') {
                    el.display = _this.rulesArr.contactMeConfig.disabled
                  } else if (el.field === 'isShow') {
                    el.props.showBtn = !_this.rulesArr.contactMeConfig.disabled
                  }
                })
                _this.rulesArr.joinChatConfig.rules.forEach((el) => {
                  if (
                    [
                      'ID',
                      'btnText',
                      'fontWeight',
                      'fontColor',
                      'fontSize',
                    ].includes(el.field)
                  ) {
                    el.display = !_this.rulesArr.joinChatConfig.disabled
                  } else if (el.field === 'isOpen') {
                    el.display = _this.rulesArr.joinChatConfig.disabled
                  } else if (el.field === 'isShow') {
                    el.props.showBtn = !_this.rulesArr.joinChatConfig.disabled
                  }
                })
                getable = false
              })
              .catch((err) => {
                getable = false
              })
          }
        }
      },
    },
    titleConfig: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.rule.forEach((el, i) => {
            if (i > 0) {
              el.display = val === 1
            }
          })
        }
      },
    },
    contactMeConfig: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.rule.forEach((el, i) => {
            if (i > 0) {
              if (_this.rulesArr.contactMeConfig.disabled) {
                el.display = el.field === 'isOpen' ? true : false
              } else {
                el.display = el.field === 'isOpen' ? false : val === 1
              }
            }
          })
        }
      },
      btnText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.rule.forEach((el, i) => {
            if (['fontWeight', 'fontColor', 'fontSize'].includes(el.field)) {
              el.display =
                val.isShow === 1 &&
                fApi.form.isShow === 1 &&
                !_this.rulesArr.contactMeConfig.disabled
            }
          })
        }
      },
    },
    joinChatConfig: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.rule.forEach((el, i) => {
            if (i > 0) {
              if (_this.rulesArr.joinChatConfig.disabled) {
                el.display = el.field === 'isOpen' ? true : false
              } else {
                el.display = val === 1
              }
            }
          })
        }
      },
      btnText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.rule.forEach((el, i) => {
            if (['fontWeight', 'fontColor', 'fontSize'].includes(el.field)) {
              el.display =
                val.isShow === 1 &&
                fApi.form.isShow === 1 &&
                !_this.rulesArr.joinChatConfig.disabled
            }
          })
        }
      },
    },
  },
  // 底部导航
  'st-bottom-nav': {
    templateData: {
      // 只有模版一的图文和纯图标需要
      fillBg: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          console.log(
            fApi.form.contentType !== 'text' &&
              fApi.form.styleType === 'template1'
          )
          rule.display =
            fApi.form.contentType !== 'text' &&
            fApi.form.styleType === 'template1'
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image'
        }
      },
      contentType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template1'
        }
      },
      defaultColor: function () {
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.contentType !== 'icon' &&
            fApi.form.styleType === 'template1'
        }
      },
      selectedColor: function () {
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.contentType !== 'icon' &&
            fApi.form.styleType === 'template1'
        }
      },
      opacity: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template2'
        }
      },
      blur: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template2'
        }
      },
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm

          // console.log(fApi, rule, val, vm)
          // 模版三需要满五个导航
          if (fApi.form.styleType === 'template3') {
            // 导航全部显示
            _this.fApiArr[1].form.list.forEach((item) => {
              item.is_show = 1
            })
            // 模版三不允许删除导航
            // _this.fApiArr[1].rule[0].props.allowDelete = false
            _this.fApiArr[1].rule[0].props.allowHide = false
            // while (_this.fApiArr[1].form.list.length !== 5) {
            //   _this.fApiArr[1].form.list.push({
            //     is_default: 0,
            //     icon_checked: '',
            //     icon_normal: '',
            //     name: '导航',
            //     is_show: 1,
            //     jump_link: null,
            //   })
            // }
          } else {
            // _this.fApiArr[1].rule[0].props.allowDelete = true
            _this.fApiArr[1].rule[0].props.allowHide = true
          }
          console.log(_this)
        }
      },
    },
    tabs: {
      list: function (vm) {
        return (val, rule, fApi) => {
          // console.log(fApi, rule, val, vm)
          // console.log(vm.fApiArr[0].form.styleType)
          const _this = vm

          // 模版三需要满五个导航
          if (vm.fApiArr[0].form.styleType === 'template3') {
            // _this.fApiArr[1].rule[0].props.allowDelete = false
            _this.fApiArr[1].rule[0].props.allowHide = false
            // while (fApi.form.list.length !== 5) {
            //   fApi.form.list.push({
            //     is_default: 0,
            //     icon_checked: '',
            //     icon_normal: '',
            //     name: '导航',
            //     is_show: 1,
            //     jump_link: null,
            //   })
            // }
          } else {
            // _this.fApiArr[1].rule[0].props.allowDelete = true
            _this.fApiArr[1].rule[0].props.allowHide = true
          }
        }
      },
    },
  },
  'st-broadcast': {
    normal: {
      // 图标颜色是否显示根据颜色类型判断
      iconColor: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.iconColorType === 'custom'
        }
      },
      // 图标颜色是否显示根据颜色类型判断
      iconColorType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template2'
        }
      },
      // 背景颜色
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color'
        }
      },
      // 图片
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image'
        }
      },
    },
  },
  'st-map': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImg: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.bgType === 'image'
        }
      },
      height: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.mapHeightType === 'custom'
        }
      },
      addressDetail: function (vm) {
        return (val, rule, fApi) => {
          const filterKey = [
            'userPosition',
            'userPhone',
            'businessTime',
            'addressDesc',
          ]
          fApi.rule.forEach((el) => {
            if (filterKey.includes(el.field)) {
              el.display = val === 1
            }
          })
        }
      },
    },
    titleConfig: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          fApi.rule.forEach((el, i) => {
            if (i > 0) {
              el.display = val === 1
            }
          })
        }
      },
    },
  },

  // "st-old-me-page": {
  //   normal: {
  //     bgImage: function (vm) {
  //       let _this = vm
  //       return (val, rule, fApi) => {
  //         if (!val) {
  //           fApi.form.bgImage = _this.baseUrl + '/static/cyc/images/wxapps/images/thumb_up.png'
  //         }
  //         rule.props.DefaultImage = _this.baseUrl + '/static/cyc/images/wxapps/images/thumb_up.png'
  //       };
  //     }
  //   }
  // },

  'st-button': {
    normal: {
      btnStyle: function (vm) {
        let _this = vm
        let isInit = true
        return (val, rule, fApi) => {
          if (!isInit) {
            switch (val) {
              case 'template1':
                fApi.form.btnColor = fApi.form.btnColor
                fApi.form.borderConfig.borderSize = 0
                fApi.form.borderRadius = 4
                break
              case 'template2':
                fApi.form.btnColor = fApi.form.btnColor
                fApi.form.borderConfig.borderSize = 1
                fApi.form.borderRadius = 4
                break
              case 'template3':
                fApi.form.btnColor = fApi.form.btnColor
                fApi.form.borderConfig.borderSize = 0
                fApi.form.borderRadius = 19
                break
              case 'template4':
                fApi.form.btnColor = fApi.form.btnColor
                fApi.form.borderConfig.borderSize = 1
                fApi.form.borderRadius = 19
                break
            }
          }
          isInit = false
        }
      },
      btnColor: function (vm) {
        let _this = vm
        return (val, rule, fApi) => {
          let btnStyle = fApi.rule.find((el) => el.field === 'btnStyle')
          btnStyle.props.backgroundColor = val
          btnStyle.props.borderColor = val
        }
      },
    },
  },

  'st-company-library': {
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
    },
  },

  'st-source': {
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // const arr = [1, 2]
          // arr.forEach((el) => {
          //   fApi.rule[el].display = val.isShow === 1
          // })
        }
      },
    },
  },

  'st-message-feedback': {
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
    },
    shareConfig: {
      shareTitle: function (vm) {
        return (val, rule, fApi) => {
          vm.rulesArr.shareConfig.isShow =
            vm.$route.params?.platform === 'mp-weixin' ? 1 : 0
        }
      },
    },
  },
  'st-shop-index': {
    header: {
      template: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          fApi.form.height =
            fApi.form.template === 'circle'
              ? 100
              : fApi.form.template === 'square'
              ? 100
              : fApi.form.height
          // fApi.form.radian = fApi.form.template === 'circle' ? 70 : fApi.form.template === 'square' ? 0 : fApi.form.radian
          fApi.form.radian = fApi.form.template === 'circle' ? 70 : 0
        }
      },
      height: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.template === 'custom' ? true : false
        }
      },
      radian: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.template === 'custom' ? true : false
          // rule.display = false
        }
      },
      opacity: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.template === 'custom' ? true : false
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    shareConfig: {
      shareTitle: function (vm) {
        return (val, rule, fApi) => {
          vm.rulesArr.shareConfig.isShow =
            vm.$route.params?.platform === 'mp-weixin' ? 1 : 0
        }
      },
    },
  },

  'st-message-feedback-content': {
    contentConfig: {
      inputTitleLimit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.inputTitleLimitType === 'custom'
        }
      },
      inputContentLimit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.inputContentLimitType === 'custom'
        }
      },
    },
    selectConfig: {
      selectData: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.isShowSelect === 1
        }
      },
      categoryRequired: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          console.log(123123)
          rule.display = fApi.form.isShowCategory === 1
        }
      },
    },
  },

  'st-mail-list': {
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
      bgType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.bgConfig === 'custom'
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'color' && fApi.form.bgConfig === 'custom'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'image' && fApi.form.bgConfig === 'custom'
        }
      },
    },
    searchConfig: {
      bgType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.bgConfig === 'custom'
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'color' && fApi.form.bgConfig === 'custom'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'image' && fApi.form.bgConfig === 'custom'
        }
      },
    },
    sortConfig: {
      // 如果是按笔画排序 或者架构 不用正序或者倒序
      isDesc: function (vm) {
        return (val, rule, fApi) => {
          if (
            fApi.form.orderBy.indexOf('char_sort') !== -1 ||
            fApi.form.orderBy.indexOf('show_sort') !== -1
          ) {
            rule.display = false
            // 只有正序
            rule.value = 0
          } else {
            rule.display = true
          }
        }
      },
    },
  },

  'st-mail-content': {
    allConfig: {
      numPosition: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.showMemberNum === 1
        }
      },
    },
    nearbyConfig: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          fApi.rule.forEach((el, index) => {
            if (index > 0) {
              el.display = val === 1
            }
          })
        }
      },
      filterData: function (vm) {
        return (val, rule, fApi) => {
          if (!val.length) {
            fApi.form.filterData = [1, 2]
          } else {
            rule.options.map((el) => {
              el.disabled = val.length === 1 && el.value === val[0]
            })
          }
          rule.display = fApi.form.showFilter === 1 && fApi.form.isShow === 1
        }
      },
    },
    sameCityConfig: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          fApi.rule.forEach((el, index) => {
            if (index > 0) {
              el.display = val === 1
            }
          })
        }
      },
      filterData: function (vm) {
        return (val, rule, fApi) => {
          if (!val.length) {
            fApi.form.filterData = [1, 2]
          } else {
            rule.options.map((el) => {
              el.disabled = val.length === 1 && el.value === val[0]
            })
          }
          rule.display = fApi.form.showFilter === 1 && fApi.form.isShow === 1
        }
      },
    },
  },
  'st-structure': {
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi, init) => {
          if (!init) {
            // 预设值
            if (val === 'template1') {
              fApi.rule[2].value = '#4A8FEC'
              vm.rulesArr.tabs.rules[1].value = '#333C50' // 预设：#333C50
              vm.rulesArr.tabs.rules[2].value = '#333C50' // 预设：#333C50
            } else {
              fApi.rule[2].value = '#f8f8f8'
              vm.rulesArr.tabs.rules[1].value = 'rgb(26, 26, 26)' // 预设：#333C50
              vm.rulesArr.tabs.rules[2].value = 'rgb(179, 179, 179)' // 预设：#333C50
            }
            fApi.rule[5].value = '#ffffff'
          } else {
            if (val === 'template1') {
              if (!vm.rulesArr.tabs.rules[1].value) {
                vm.rulesArr.tabs.rules[1].value = '#333C50' // 预设：#333C50
              }
              if (!vm.rulesArr.tabs.rules[2].value) {
                vm.rulesArr.tabs.rules[2].value = '#333C50' // 预设：#333C50
              }
            } else {
              if (!vm.rulesArr.tabs.rules[1].value) {
                vm.rulesArr.tabs.rules[1].value = 'rgb(26, 26, 26)' // 预设：#333C50
              }
              if (!vm.rulesArr.tabs.rules[2].value) {
                vm.rulesArr.tabs.rules[2].value = 'rgb(179, 179, 179)' // 预设：#333C50
              }
            }
          }
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.bgType === 'image'
        }
      },
      contentBgColor: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.contentBgType === 'color'
        }
      },
      contentBgImage: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.contentBgType === 'image'
        }
      },
    },
    otherConfig: {
      // 展示层级
      showLevel: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.showFilter === 1
        }
      },
      // 展示会员身份   直接显示
      showIdentity: function () {
        return (val, rule, fApi) => {
          // rule.display = store.state.appDesign.memberIdentity === 1
          rule.display = true
        }
      },
      // 架构图示
      photo: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.showPhoto === 1
        }
      },
    },
    searchConfig: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          for (let i = 1; i < fApi.rule.length; i++) {
            fApi.rule[i].display = val === 1
          }
        }
      },
    },
    shareConfig: {
      showShare: function () {
        return (val, rule, fApi) => {
          fApi.rule.forEach((el, i) => {
            if (i > 2) {
              if (fApi.rule[i].field === 'showPosterShare') {
              } else fApi.rule[i].display = val === 1
            }
          })
        }
      },
    },
  },

  'st-activity-images': {
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
    },
  },

  'st-activity-detail': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          vm.rulesArr.consultBtn.isShow = store.state.tenant.features.includes(
            'consult'
          )
            ? 1
            : 0

          vm.rulesArr.normal.isShow = 0
        }
      },
    },
    serviceBtn: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          vm.rulesArr.serviceBtn.isShow =
            vm.$route.params?.platform === 'mp-weixin' ? 1 : 0
        }
      },
    },
  },

  'st-business-association': {
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
      bgType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.bgConfig === 'custom'
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'color' && fApi.form.bgConfig === 'custom'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'image' && fApi.form.bgConfig === 'custom'
        }
      },
    },
    searchConfig: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          for (let i = 1; i < fApi.rule.length; i++) {
            fApi.rule[i].display = val === 1
          }
        }
      },
      bgType: function (vm) {
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.bgConfig === 'custom' && fApi.form.isShow === 1
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'color' &&
            fApi.form.bgConfig === 'custom' &&
            fApi.form.isShow === 1
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'image' &&
            fApi.form.bgConfig === 'custom' &&
            fApi.form.isShow === 1
        }
      },
    },
    contentConfig: {
      filterAreaType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.isFilterArea === 1
        }
      },
    },
  },

  'st-business-association-home': {
    normal: {
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // if (!val) {
          //   fApi.form.bgImage = _this.baseUrl + '/static/gov/images/shzj.png'
          // }
          rule.props.DefaultImage =
            _this.baseUrl + '/static/gov/images/shzj.png'
        }
      },
    },
    filterConfig: {
      filterAreaType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.isFilterArea === 1
        }
      },
    },
  },

  'st-member-index': {
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3, 4]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
    },
    contentConfig: {
      positionMultiple: function (vm) {
        return (val, rule, fApi) => {
          rule.display = !!fApi.form.isShowTitleName
          fApi.rule.forEach((el) => {
            if (el.field === 'positionOrder') {
              el.display = val === 1 && rule.display
            }
          })
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // if (!val) {
          //   fApi.form.bgImage = _this.baseUrl + '/static/cyc/images/wxapps/images/userbackground5.png'
          // }
          rule.props.DefaultImage =
            _this.baseUrl +
            '/static/cyc/images/wxapps/images/userbackground5.png'
        }
      },
      showQuickEnter() {
        return (val, rule, fApi) => {
          console.log(fApi.rule)
          fApi.rule.forEach((r) => {
            if (r.type === 'StObjSelector') {
              r.display = val === 1
            }
          })
        }
      },
      quickEnterOne: function (vm) {
        return (val, rule, fApi) => {
          rule.props.options = store.state.appDesign.memberIndexQuickEnters
          if (rule.props.options.length === 0) {
            Vue.bus.on('form-update', ({ key, val }) => {
              if (key !== 'memberIndexQuickEnters') return
              rule.props.options = val
            })
          }
          rule.props.options.map((el) => {
            if (
              [
                fApi.form.quickEnterTwo.value,
                fApi.form.quickEnterThree.value,
                fApi.form.quickEnterFour.value,
              ].includes(el.value) &&
              el.value !== ''
            ) {
              el.disabled = true
            } else {
              el.disabled = false
            }
          })
        }
      },
      quickEnterTwo: function (vm) {
        return (val, rule, fApi) => {
          let data = store.state.appDesign.memberIndexQuickEnters
          rule.props.options = JSON.parse(JSON.stringify(data))
          if (rule.props.options.length === 0) {
            Vue.bus.on('form-update', ({ key, val }) => {
              if (key !== 'memberIndexQuickEnters') return
              rule.props.options = JSON.parse(JSON.stringify(val))
            })
          }
          rule.props.options.map((el) => {
            if (
              [
                fApi.form.quickEnterOne.value,
                fApi.form.quickEnterThree.value,
                fApi.form.quickEnterFour.value,
              ].includes(el.value) &&
              el.value !== ''
            ) {
              el.disabled = true
            } else {
              el.disabled = false
            }
          })
        }
      },
      quickEnterThree: function (vm) {
        return (val, rule, fApi) => {
          let data = store.state.appDesign.memberIndexQuickEnters
          rule.props.options = JSON.parse(JSON.stringify(data))
          if (rule.props.options.length === 0) {
            Vue.bus.on('form-update', (data) => {
              if (data.key !== 'memberIndexQuickEnters') return
              rule.props.options = JSON.parse(JSON.stringify(data.val))
            })
          }
          rule.props.options.map((el) => {
            if (
              [
                fApi.form.quickEnterOne.value,
                fApi.form.quickEnterTwo.value,
                fApi.form.quickEnterFour.value,
              ].includes(el.value) &&
              el.value !== ''
            ) {
              el.disabled = true
            } else {
              el.disabled = false
            }
          })
        }
      },
      quickEnterFour: function (vm) {
        return (val, rule, fApi) => {
          let data = store.state.appDesign.memberIndexQuickEnters
          rule.props.options = JSON.parse(JSON.stringify(data))
          if (rule.props.options.length === 0) {
            Vue.bus.on('form-update', (data) => {
              if (data.key !== 'memberIndexQuickEnters') return
              rule.props.options = JSON.parse(JSON.stringify(data.val))
            })
          }
          rule.props.options.map((el) => {
            if (
              [
                fApi.form.quickEnterOne.value,
                fApi.form.quickEnterTwo.value,
                fApi.form.quickEnterThree.value,
              ].includes(el.value) &&
              el.value !== ''
            ) {
              el.disabled = true
            } else {
              el.disabled = false
            }
          })
        }
      },
    },
    shareConfig: {
      showShare: function () {
        return (val, rule, fApi) => {
          fApi.rule.forEach((el, i) => {
            if (i > 2 && i < 4) {
              fApi.rule[i].display = val === 1
            }
          })
        }
      },
    },
  },
  'st-member-message': {
    contentConfig: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
      msgList: function (vm) {
        let requestable = true
        return (val, rule, fApi) => {
          const _this = vm
          if (requestable) {
            infoComponentSelect()
              .then((res) => {
                rule.props.options = res.data
                if (val.length) {
                  fApi.form.msgList.forEach((el, index) => {
                    if (
                      rule.props.options.findIndex(
                        (item) => item.value === el
                      ) === -1
                    ) {
                      fApi.form.msgList.splice(index, 1)
                    }
                  })
                }
                if (!val.length) {
                  // fApi.form.showMsg = ["gender", "phone"];
                } else if (val.length === 1) {
                  rule.props.options.forEach((el) => {
                    if (el.value === val[0]) {
                      el.disabled = true
                    }
                  })
                } else if (val.length > 1 && val.length < 6) {
                  rule.props.options.forEach((el) => {
                    el.disabled = false
                  })
                } else if (val.length === 6) {
                  rule.props.options.forEach((el) => {
                    if (!val.includes(el.value)) {
                      el.disabled = true
                    }
                  })
                }
                requestable = false
              })
              .catch((err) => {})
          } else {
            if (val.length) {
              fApi.form.msgList.forEach((el, index) => {
                if (
                  rule.props.options.findIndex((item) => item.value === el) ===
                  -1
                ) {
                  fApi.form.msgList.splice(index, 1)
                }
              })
            }
            if (!val.length) {
              // fApi.form.showMsg = ["gender", "phone"];
            } else if (val.length === 1) {
              rule.props.options.forEach((el) => {
                if (el.value === val[0]) {
                  el.disabled = true
                }
              })
            } else if (val.length > 1 && val.length < 6) {
              rule.props.options.forEach((el) => {
                el.disabled = false
              })
            } else if (val.length === 6) {
              rule.props.options.forEach((el) => {
                if (!val.includes(el.value)) {
                  // el.disabled = true;
                }
              })
            }
          }
        }
      },
      msgListDrag: function (vm) {
        return (val, rule, fApi) => {
          console.log(fApi.form.msgList)
          // 初始化，赋值msglist 的值
          if (fApi.form.msgListDrag.length === 0 && fApi.form.msgList !== 0) {
            fApi.form.msgListDrag = fApi.form.msgList
          } else if (fApi.form.msgListDrag.length > fApi.form.msgList.length) {
            // 个人信息展示做了取消选择操作
            let index = fApi.form.msgListDrag.findIndex(
              (item) => !fApi.form.msgList.includes(item)
            )
            if (index !== -1) {
              // 删除对应索引的排序
              fApi.form.msgListDrag.splice(index, 1)
            }
          } else if (fApi.form.msgListDrag.length < fApi.form.msgList.length) {
            // 个人信息展示做了添加选择操作
            let index = fApi.form.msgList.findIndex(
              (item) => !fApi.form.msgListDrag.includes(item)
            )
            if (index !== -1) {
              //添加到底部
              fApi.form.msgListDrag.push(fApi.form.msgList[index])
            }
          }

          console.log(val, rule, fApi)
        }
      },
      msgListUnit: function (vm) {
        let requestable = true
        return (val, rule, fApi) => {
          const _this = vm
          if (requestable) {
            infoComponentSelect({
              pageset: 'unit',
            })
              .then((res) => {
                rule.props.options = res.data
                if (val.length) {
                  fApi.form.msgListUnit.forEach((el, index) => {
                    if (
                      rule.props.options.findIndex(
                        (item) => item.value === el
                      ) === -1
                    ) {
                      fApi.form.msgListUnit.splice(index, 1)
                    }
                  })
                }
                if (!val.length) {
                  // fApi.form.showMsg = ["gender", "phone"];
                } else if (val.length === 1) {
                  rule.props.options.forEach((el) => {
                    if (el.value === val[0]) {
                      el.disabled = true
                    }
                  })
                } else if (val.length > 1 && val.length < 6) {
                  rule.props.options.forEach((el) => {
                    el.disabled = false
                  })
                } else if (val.length === 6) {
                  rule.props.options.forEach((el) => {
                    if (!val.includes(el.value)) {
                      el.disabled = true
                    }
                  })
                }
                requestable = false
              })
              .catch((err) => {})
          } else {
            if (val.length) {
              fApi.form.msgListUnit.forEach((el, index) => {
                if (
                  rule.props.options.findIndex((item) => item.value === el) ===
                  -1
                ) {
                  fApi.form.msgListUnit.splice(index, 1)
                }
              })
            }
            if (!val.length) {
              // fApi.form.showMsg = ["gender", "phone"];
            } else if (val.length === 1) {
              rule.props.options.forEach((el) => {
                if (el.value === val[0]) {
                  el.disabled = true
                }
              })
            } else if (val.length > 1 && val.length < 6) {
              rule.props.options.forEach((el) => {
                el.disabled = false
              })
            } else if (val.length === 6) {
              rule.props.options.forEach((el) => {
                if (!val.includes(el.value)) {
                  // el.disabled = true;
                }
              })
            }
          }
        }
      },
      msgListUnitDrag: function (vm) {
        return (val, rule, fApi) => {
          console.log(fApi.form.msgListUnit)
          // 初始化，赋值msglist 的值
          if (
            fApi.form.msgListUnitDrag.length === 0 &&
            fApi.form.msgListUnit !== 0
          ) {
            fApi.form.msgListUnitDrag = fApi.form.msgListUnit
          } else if (
            fApi.form.msgListUnitDrag.length > fApi.form.msgListUnit.length
          ) {
            // 信息展示做了取消选择操作
            let index = fApi.form.msgListUnitDrag.findIndex(
              (item) => !fApi.form.msgListUnit.includes(item)
            )
            if (index !== -1) {
              // 删除对应索引的排序
              fApi.form.msgListUnitDrag.splice(index, 1)
            }
          } else if (
            fApi.form.msgListUnitDrag.length < fApi.form.msgListUnit.length
          ) {
            // 信息展示做了添加选择操作
            let index = fApi.form.msgListUnit.findIndex(
              (item) => !fApi.form.msgListUnitDrag.includes(item)
            )
            if (index !== -1) {
              //添加到底部
              fApi.form.msgListUnitDrag.push(fApi.form.msgListUnit[index])
            }
          }

          console.log(val, rule, fApi)
        }
      },
    },
  },

  'st-new-trends': {
    contentConfig: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
    },
  },

  'st-honor-certificate': {
    contentConfig: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
    },
  },

  'st-about-me': {
    contentConfig: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
      msgList: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          if (!val.length) {
            fApi.form.msgList = [
              {
                name: '自我介绍',
                key: 'description',
                is_show: 1,
              },
              {
                name: '喜爱书籍',
                key: 'like_books',
                is_show: 1,
              },
              {
                name: '喜爱照片',
                key: 'like_photo',
                is_show: 1,
              },
              {
                name: '喜爱视频',
                key: 'like_video',
                is_show: 1,
              },
            ]
          }
        }
      },
    },
  },

  'st-related-company': {
    contentConfig: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
    },
  },

  'st-hot-topic': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image'
        }
      },
    },
  },

  'st-circle-group': {
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },

      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      orderBy: function (vm) {
        return (val, rule, fApi, init) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      isDesc: function (vm) {
        return (val, rule, fApi, init) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
    },
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image'
        }
      },
    },
    templateData: {
      // 色块颜色
      swiperColor: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template2'
        }
      },
      // 展示类型
      dataShowType: function () {
        return (val, rule, fApi) => {
          console.log(fApi.form.groupDataShow)
          rule.display = fApi.form.groupDataShow
        }
      },
    },
    listTitle: {
      //  上传艺术字体图片
      artFontPhoto: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.titleFamily === 'template1'
        }
      },

      //  文本颜色
      titleColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.titleFamily === 'template2'
        }
      },
      //  文本粗细
      fontWeight: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.titleFamily === 'template2'
        }
      },
      //  标题大小
      fontSize: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.titleFamily === 'template2'
        }
      },
      //  标题内容
      title: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.titleFamily === 'template2'
        }
      },
    },
  },

  'st-community-talent': {
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },

      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      // 选择类型
      category_type: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      // 对应小组type 的id
      group_category_ids: function (vm) {
        return (val, rule, fApi, init) => {
          console.log(fApi, rule)
          rule.display =
            fApi.form.dataType === 'filter' &&
            fApi.form.category_type === 'circle_group'
          if (rule.props.options.length === 0) {
            rule.props.options = store.state.appDesign.hotGroupList
            console.log(
              store.state.appDesign.hotGroupList,
              'store.state.appDesign.hotGroupList'
            )
          }
          //  没有默认选中全部
          if (!rule.value.length) {
            rule.value = store.state.appDesign.hotGroupList.map(
              (item) => item.id
            )
          }
        }
      },
      // 对应架构type 的id
      organization_category_ids: function (vm) {
        return (val, rule, fApi, init) => {
          console.log(fApi, rule)
          rule.display =
            fApi.form.dataType === 'filter' &&
            fApi.form.category_type === 'cyc_organization'
          if (rule.props.options.length === 0) {
            rule.props.options = store.state.appDesign.parentToList
            console.log(
              store.state.appDesign.parentToList,
              'store.state.appDesign.hotGroupList'
            )
          }
          //  没有默认选中全部
          if (!rule.value.length) {
            rule.value = store.state.appDesign.parentToList.map(
              (item) => item.id
            )
            console.log(rule.value)
          }
        }
      },
      order_by: function (vm) {
        return (val, rule, fApi, init) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      isDesc: function (vm) {
        return (val, rule, fApi, init) => {
          console.log(fApi.form)
          rule.display =
            fApi.form.dataType === 'filter' &&
            fApi.form.order_by === 'interact_point'
        }
      },
    },
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image'
        }
      },
    },
    templateData: {
      // 色块颜色
      swiperColor: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template2'
        }
      },
      // 展示类型
      dataShowType: function () {
        return (val, rule, fApi) => {
          console.log(fApi.form.groupDataShow)
          rule.display = fApi.form.groupDataShow
        }
      },
      // 数据加载
      loadingType: function () {
        return (val, rule, fApi) => {
          console.log(fApi.form.groupDataShow)
          rule.display = fApi.form.styleType === 'template1'
          if (
            fApi.form.styleType !== 'template1' &&
            fApi.form.loadingType != 'none'
          ) {
            fApi.form.loadingType = 'none'
          }
        }
      },
      // 色块颜色
      dataLimit: function () {
        return (val, rule, fApi) => {
          rule.display = fApi.form.loadingType === 'none'
        }
      },
      // 加载样式
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      // 加载文字
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
    listTitle: {
      //  上传艺术字体图片
      artFontPhoto: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.titleFamily === 'template1'
        }
      },

      //  文本颜色
      titleColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.titleFamily === 'template2'
        }
      },
      //  文本粗细
      fontWeight: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.titleFamily === 'template2'
        }
      },
      //  标题大小
      fontSize: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.titleFamily === 'template2'
        }
      },
      //  标题内容
      title: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.titleFamily === 'template2'
        }
      },
    },
  },

  'st-posts-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image'
        }
      },
    },
  },

  'st-contact-us': {
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image'
          // if (!val) {
          //   fApi.form.bgImage = _this.baseUrl + '/static/cyc/images/wxapps/images/contact-us-bg.png'
          // }
          rule.props.DefaultImage =
            _this.baseUrl + '/static/cyc/images/wxapps/images/contact-us-bg.png'
        }
      },
    },
    contentConfig: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
      logo: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // if (!val) {
          //   fApi.form.logo = _this.baseUrl + '/static/cyc/images/wxapps/images/logo-icon.png'
          // }
          rule.props.DefaultImage =
            _this.baseUrl + '/static/cyc/images/wxapps/images/logo-icon.png'
        }
      },
    },
    contactsConfig: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [1, 2, 3]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
    },
    mapConfig: {
      mapHeight: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.mapHeightType === 'custom'
        }
      },
    },
    shareConfig: {
      showShare: function () {
        return (val, rule, fApi) => {
          fApi.rule.forEach((el, i) => {
            if (i > 2) {
              fApi.rule[i].display = val === 1
            }
          })
        }
      },
    },
  },

  'st-follow-us': {
    normal: {
      titleConfig: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const arr = [2, 3, 4]
          arr.forEach((el) => {
            fApi.rule[el].display = val.isShow === 1
          })
        }
      },
    },
  },

  // 文章详情精简版
  'st-article-detail-advance': {
    share: {
      type: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          let arr = [1, 2]
          arr.forEach((el) => {
            fApi.rule[el].display = val === 2
          })
        }
      },
    },
  },

  // 文章详情精简版，已废弃
  'st-article-detail': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          vm.rulesArr.consultBtn.isShow = store.state.tenant.features.includes(
            'consult'
          )
            ? 1
            : 0
        }
      },
    },
    serviceBtn: {
      isShow: function (vm) {
        return (val, rule, fApi) => {
          vm.rulesArr.serviceBtn.isShow =
            vm.$route.params?.platform === 'mp-weixin' ? 1 : 0
        }
      },
    },
  },

  'st-online-learning': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          _this.rulesArr.templateData.rules[1].display = val === 'template1'
          _this.rulesArr.templateData.rules[2].display = val === 'template2'
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          fApi.form.dataAll = val === 'select' ? 1 : fApi.form.dataAll
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },
      orderRule: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val === 1) {
            fApi.form.dataFilter = []
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataFilter: function (vm) {
        return (val, rule, fApi, init) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0
          learnCategoryAll({ data_sources: fApi.form.dataShowType })
            .then((res) => {
              console.log(res.data)
              if (res.data.length !== rule.props.options.length) {
                // 清空之前的所有选项
                rule.value = []
              }
              rule.props.options = res.data
              if (!rule.value.length) {
                rule.value = res.data.map((item) => item.value)
              }
              console.log(rule)
            })
            .catch((err) => {})
        }
      },
      dataShowType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },
  'st-college-teacher-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          console.log(vm, '123123')
          // _this.rulesArr.templateData.rules[1].display = val === 'template1'
          // _this.rulesArr.templateData.rules[2].display = val === 'template2'
          // 数据加载是否隐藏
          _this.rulesArr.loadingConfig.rules[0].display = val !== 'template1'
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          fApi.form.dataAll = val === 'select' ? 1 : fApi.form.dataAll
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },
  'st-college-audio-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
        }
      },
      fontColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.styleType == 'template3'
        }
      },
      bgType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.isCustomBg && fApi.form.styleType == 'template3'
        }
      },
      isCustomBg: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.styleType == 'template3'
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'color' &&
            fApi.form.isCustomBg &&
            fApi.form.styleType == 'template3'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'image' &&
            fApi.form.isCustomBg &&
            fApi.form.styleType == 'template3'
        }
      },
    },

    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          fApi.form.dataAll = val === 'select' ? 1 : fApi.form.dataAll
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val === 1) {
            fApi.form.dataFilter = []
          }
        }
      },

      dataFilter: function (vm) {
        return (val, rule, fApi, init) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0
          if (rule.props.options.length === 0) {
            // rule.props.options = store.state.appDesign.brandCategoryOptions

            collegeCategoryOptions({ tab: 'normal' })
              .then((res) => {
                rule.props.options = res.data.list
                if (!rule.value.length) {
                  rule.value = res.data.map((item) => item.id)
                }
              })
              .catch((err) => {})
          }
        }
      },
      orderRule: function (vm) {
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.randType === 0
        }
      },
      randType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      randLimit: function (vm) {
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.randType === 1
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },
  'st-college-recommend-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
        }
      },
      fontColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.styleType == 'template3'
        }
      },
      bgType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.isCustomBg && fApi.form.styleType == 'template3'
        }
      },
      isCustomBg: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.styleType == 'template3'
        }
      },
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'color' &&
            fApi.form.isCustomBg &&
            fApi.form.styleType == 'template3'
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display =
            fApi.form.bgType === 'image' &&
            fApi.form.isCustomBg &&
            fApi.form.styleType == 'template3'
        }
      },
    },

    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          fApi.form.dataAll = val === 'select' ? 1 : fApi.form.dataAll
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val === 1) {
            fApi.form.dataFilter = []
          }
        }
      },

      dataFilter: function (vm) {
        return (val, rule, fApi, init) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0
          if (rule.props.options.length === 0) {
            // rule.props.options = store.state.appDesign.brandCategoryOptions

            collegeCategoryOptions({ tab: 'normal' })
              .then((res) => {
                rule.props.options = res.data.list
                if (!rule.value.length) {
                  rule.value = res.data.map((item) => item.id)
                }
              })
              .catch((err) => {})
          }
        }
      },
      orderRule: function (vm) {
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.randType === 0
        }
      },
      randType: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
      randLimit: function (vm) {
        return (val, rule, fApi) => {
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.randType === 1
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },
  'st-college-ele-book-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          console.log(vm, '123123')
          // _this.rulesArr.templateData.rules[1].display = val === 'template1'
          // _this.rulesArr.templateData.rules[2].display = val === 'template2'
          // 数据加载是否隐藏
          _this.rulesArr.loadingConfig.rules[0].display = val !== 'template1'
        }
      },
    },
    dataConfig: {
      dataType: function (vm) {
        return (val, rule, fApi) => {
          fApi.form.dataAll = val === 'select' ? 1 : fApi.form.dataAll
          if (val === 'select') {
            fApi.form.dataFilter = []
          }
        }
      },
      dataArr: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'select'
        }
      },
      dataAll: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
          if (val === 1) {
            fApi.form.dataFilter = []
          }
        }
      },

      dataFilter: function (vm) {
        return (val, rule, fApi, init) => {
          if (init) {
            if (fApi.form.dataAll && val.length && val[0]) fApi.form.dataAll = 0
          }
          rule.display =
            fApi.form.dataType === 'filter' && fApi.form.dataAll === 0
          if (rule.props.options.length === 0) {
            // rule.props.options = store.state.appDesign.brandCategoryOptions

            collegeCategoryOptions({ tab: 'normal' })
              .then((res) => {
                rule.props.options = res.data.list
                if (!rule.value.length) {
                  rule.value = res.data.map((item) => item.id)
                }
              })
              .catch((err) => {})
          }
        }
      },
      orderRule: function (vm) {
        return (val, rule, fApi) => {
          rule.display = fApi.form.dataType === 'filter'
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },
  'st-journal-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          _this.rulesArr.templateData.rules[1].display = val === 'template1'
          _this.rulesArr.templateData.rules[2].display = val === 'template2'
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },
  'st-employ-career-list': {
    normal: {
      bgColor: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'color' ? true : false
        }
      },
      bgImage: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          rule.display = fApi.form.bgType === 'image' ? true : false
        }
      },
    },
    dataConfig: {
      region: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          // _this.rulesArr.templateData.rules[1].display = val === 'template1'
          // rule.props
          console.log()
          rule.display = !fApi.form.dataAll
          getCityJson().then((res) => {
            console.log(res, 'res,123')
            rule.props.options = res.data
          })
          console.log(rule.props, 'rule')
        }
      },
    },
    templateData: {
      styleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          _this.rulesArr.templateData.rules[1].display = val === 'template1'
          // _this.rulesArr.templateData.rules[2].display = val === 'template2'
        }
      },
    },
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },

  'st-activity-posts': {
    loadingConfig: {
      limit: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const titleName = {
            none: '数据条数',
            handle: '每次加载',
            page: '每页加载',
          }
          rule.title = titleName[fApi.form.loadingType]
        }
      },
      handleType: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
      handleText: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm
          const loadingType = fApi.form.loadingType
          rule.display = loadingType === 'handle'
        }
      },
    },
  },
}

export default update
