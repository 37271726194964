<template>
  <div>
    <el-dialog
      :title="readonly ? '页面路径查询' : '设置链接地址'"
      :visible.sync="openDialog"
      width="900px"
      class="dialog-vertical"
      append-to-body
      :close-on-click-modal="readonly"
    >
      <!-- <div class="link-type-select">
      <p class="select-label">链接类型</p>
      <el-select class="selector" v-model="linkType" :loading="!linkOptions.length" placeholder="请选择链接类型"
        @change="handleLinkType()">
        <el-option v-for="(item, index) in linkOptions" :key="item.index" :value="item.index"
          :label="item.name"></el-option>
      </el-select>
    </div> -->
      <div class="link-content">
        <div
          class="table-type-list"
          :style="{ height: readonly ? '430px' : '' }"
          v-loading="linkOptionsLoading"
        >
          <!-- <div v-if="linkTypeOption">
          <div :class="['type-list-item', selectedType === item.index ? 'active' : '']"
            v-for="(item, index) in linkTypeOption" :key="item.index" @click="selectTypeOption(item)">
            <p class="item-name">{{item.name}}</p>
          </div>
        </div> -->
          <el-scrollbar class="menu-scrollbar">
            <el-menu
              v-if="linkOptions.length"
              class="el-menu-vertical-demo"
              :default-active="selectedType"
              unique-opened
            >
              <template v-for="(item, i) in linkOptions">
                <el-menu-item
                  v-if="!item.children || !item.children.length"
                  :index="item.index"
                  @click="selectTypeOption(item)"
                  :key="item.id"
                >
                  {{ item.name }}
                </el-menu-item>
                <el-submenu v-else :key="i" :index="i.toString()">
                  <template slot="title">
                    <span style="color: initial">{{ item.name }}</span>
                  </template>
                  <template v-for="(child, j) in item.children">
                    <template v-if="child.children && child.children.length">
                      <el-submenu :index="j.toString()" :key="j">
                        <template slot="title">
                          <span style="color: initial">{{ child.name }}</span>
                        </template>
                        <el-menu-item
                          v-for="(g, gi) in child.children"
                          :key="gi"
                          :index="g.index"
                          @click="selectTypeOption(g)"
                          >{{ g.name }}
                        </el-menu-item>
                      </el-submenu>
                    </template>
                    <el-menu-item
                      v-else
                      :key="j"
                      :index="child.index"
                      @click="selectTypeOption(child)"
                    >
                      {{ child.name }}
                    </el-menu-item>
                  </template>
                </el-submenu>
              </template>
            </el-menu>
          </el-scrollbar>
        </div>
        <div class="select-table" v-if="is_list">
          <div class="head-filter">
            <div v-if="selectedType === 'article_data'" class="filter-item">
              <p>文章来源</p>
              <el-select
                size="medium"
                v-model="filterForm.source_id"
                placeholder="选择来源"
                @change="handleFilter"
              >
                <el-option
                  v-for="item in AllSourceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="filter-item">
              <p>搜索{{ searchKey[selectedType] }}</p>
              <el-input
                size="medium"
                v-model="filterForm.keyword"
                placeholder="请输入名称"
                @change="handleFilter"
              >
                <i
                  class="el-icon-search el-input__icon cursor-pointer"
                  slot="suffix"
                ></i>
              </el-input>
            </div>
            <div v-if="selectedType === 'circle_menu_data'" class="filter-item">
              <p>资源类型</p>
              <el-select
                size="medium"
                v-model="filterForm.is_demand"
                placeholder="选择资源分类"
                @change="handleFilter"
              >
                <el-option
                  v-for="item in demandArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div
              v-if="
                [
                  'wx_store_data',
                  'article_subject_data',
                  'circle_menu_data',
                ].includes(selectedType)
              "
              :style="{
                marginTop: searchKey[selectedType] === '资源' ? '12px' : '',
              }"
              class="filter-item"
            >
              <p>{{ searchKey[selectedType] }}分类</p>
              <el-select
                size="medium"
                v-model="filterForm.category_id"
                :placeholder="`选择${searchKey[selectedType]}分类`"
                @change="handleFilter"
              >
                <el-option
                  v-for="item in categoryArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="member-list" v-if="is_list === 1" v-loading="loading">
            <el-table
              v-show="titleList"
              stripe
              :data="linkList"
              class="thead-light"
              style="width: 100%"
              :height="readonly ? 334 : 250"
              ref="multipleTable"
              @row-click="handleCurrentChange"
              v-loadmore="LoadMore"
            >
              <!--            <el-table-column type="index" label="序号" />-->
              <!-- 单选 -->
              <el-table-column
                label="操作"
                fixed="right"
                :width="
                  (readonly && platform !== 'h5' ? 180 : readonly ? 200 : 70) +
                  70
                "
                :key="Math.random()"
              >
                <template slot-scope="scope">
                  <el-radio
                    v-if="!readonly"
                    :label="scope.row"
                    v-model="Selected"
                    ><span></span
                  ></el-radio>
                  <template v-else>
                    <!--                    <el-button-->
                    <!--                      v-if="platform === 'h5'"-->
                    <!--                      type="text"-->
                    <!--                      style="padding: 0"-->
                    <!--                      @click="showH5Code(scope.row)"-->
                    <!--                      >二维码</el-button-->
                    <!--                    >-->
                    <!--                    <template v-else-if="isWxApplet">-->
                    <!--&lt;!&ndash;                      <el-button&ndash;&gt;-->
                    <!--&lt;!&ndash;                        type="text"&ndash;&gt;-->
                    <!--&lt;!&ndash;                        style="padding: 0"&ndash;&gt;-->
                    <!--&lt;!&ndash;                        @click="showTwoCodes(scope.row)"&ndash;&gt;-->
                    <!--&lt;!&ndash;                        >小程序码</el-button&ndash;&gt;-->
                    <!--&lt;!&ndash;                      >&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                    <more-btn v-if="isWxApplet">&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      <template #more>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        <el-button type="text" style="padding: 0"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        >小程序码</el-button&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        >&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      </template>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      <el-dropdown-item&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                              @click.native="showCode(scope.row, 'trial')"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      >体验版</el-dropdown-item&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      >&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      <el-dropdown-item&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                              @click.native="showCode(scope.row, 'release')"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      >正式版</el-dropdown-item&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      >&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                    </more-btn>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                    <el-divider direction="vertical"></el-divider>&ndash;&gt;-->
                    <!--                    </template>-->
                    <el-button
                      v-if="platform === 'h5' && scope.row.mp_link"
                      type="text"
                      style="padding: 0"
                      @click="openLink(scope.row.mp_link)"
                      >查看
                    </el-button>
                    <el-button
                      @click="getURLLink(scope.row)"
                      style="padding: 0"
                      type="text"
                      >生成短信 URL Link</el-button
                    >
                    <el-button
                      type="text"
                      style="padding: 0"
                      @click="readPath(scope.row)"
                      >复制链接</el-button
                    >
                  </template>
                </template>
              </el-table-column>
              <template
                v-for="(th, index) in (titleList && titleList.index) ||
                thead[selectedType]"
              >
                <el-table-column
                  show-overflow-tooltip
                  :prop="th.prop"
                  :label="th.label || th"
                  :min-width="th.minWidth || ''"
                  :key="index"
                >
                  <template slot-scope="scope">
                    <!-- 图片 -->
                    <template v-if="titleList.value[index] === 'avatar'">
                      <ListImage
                        fit="cover"
                        :src="scope.row[th.prop || titleList.value[index]]"
                      ></ListImage>
                    </template>
                    <span v-else>{{
                      scope.row[th.prop || titleList.value[index]] | placeholder
                    }}</span>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
          <!-- is_list: 2 -->
          <!-- 系统页的分页加载没生效，每一页返回的数据居然都是一样的！ v-loadmore="LoadMore"  -->
          <el-scrollbar
            class="page-list"
            v-else
            v-loading="loading"
            :style="{ height: readonly ? '340px' : '' }"
          >
            <div
              class="lv-one-pages"
              v-for="(lvOne, index) in linkList"
              :key="lvOne.id"
            >
              <div class="lv-one-item" :id="lvOne.system_page || lvOne.id">
                <el-radio
                  v-if="!readonly"
                  class="page-radio"
                  v-model="Selected"
                  :label="JSON.stringify(lvOne.jump_link)"
                >
                  {{ lvOne.name }}
                </el-radio>
                <div v-else class="w-100">
                  <div class="page-radio">
                    <el-button-group class="flex">
                      <el-button
                        @click="getURLLink(lvOne)"
                        style="padding: 0"
                        type="text"
                        >生成短信 URL Link</el-button
                      >
                      <el-divider direction="vertical" />
                      <el-button
                        @click="readPath(lvOne)"
                        style="padding: 0"
                        type="text"
                        >复制链接</el-button
                      >
                    </el-button-group>

                    {{ lvOne.name }}
                  </div>
                </div>
              </div>
              <template v-if="lvOne._children">
                <div
                  class="lv-two-pages"
                  v-for="lvTwo in lvOne._children"
                  :key="lvTwo.id"
                >
                  <div class="lv-two-item" :id="lvTwo.system_page || lvTwo.id">
                    <el-radio
                      v-if="!readonly"
                      class="page-radio"
                      v-model="Selected"
                      :label="JSON.stringify(lvTwo.jump_link)"
                    >
                      {{ lvTwo.name }}
                    </el-radio>
                    <div v-else class="w-100">
                      <div class="page-radio">
                        <el-button-group class="flex">
                          <el-button
                            @click="getURLLink(lvTwo)"
                            style="padding: 0"
                            type="text"
                            >生成短信 URL Link</el-button
                          >
                          <el-divider direction="vertical" />
                          <el-button
                            @click="readPath(lvTwo)"
                            style="padding: 0"
                            type="text"
                            >复制链接</el-button
                          >
                        </el-button-group>
                        {{ lvTwo.name }}
                      </div>
                    </div>
                  </div>
                  <template v-if="lvTwo._children">
                    <div
                      class="lv-three-pages"
                      v-for="lvThree in lvTwo._children"
                      :key="lvThree.id"
                      :id="lvThree.system_page || lvThree.id"
                    >
                      <el-radio
                        v-if="!readonly"
                        class="page-radio"
                        v-model="Selected"
                        :label="JSON.stringify(lvThree.jump_link)"
                      >
                        {{ lvThree.name }}
                      </el-radio>
                      <div class="w-100 flex" v-else>
                        <div class="page-radio">
                          <el-button-group class="flex">
                            <el-button
                              @click="getURLLink(lvThree)"
                              style="padding: 0"
                              type="text"
                              >生成短信 URL Link</el-button
                            >
                            <el-divider direction="vertical" />
                            <el-button
                              @click="readPath(lvThree)"
                              style="padding: 0"
                              type="text"
                              >复制链接</el-button
                            >
                          </el-button-group>
                          {{ lvThree.name }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </el-scrollbar>
        </div>
        <div v-else class="select-table">
          <el-form
            v-if="Selected && Selected.jump_config"
            :model="Selected.jump_config"
            :rules="rules"
            ref="select"
            size="medium"
            label-position="right"
            label-width="100px"
            class="small-form"
            @submit.native.prevent
          >
            <el-form-item
              v-if="selectedType !== 'phone_link'"
              :label="selectedType === 'mp_link' ? '名称：' : '网页名称：'"
              prop="name"
            >
              <el-input
                v-model="Selected.jump_config.name"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="selectedType === 'mp_link'"
              label="AppID："
              prop="appid"
            >
              <el-input
                v-model="Selected.jump_config.appid"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="selectedType === 'mp_link'"
              label="页面路径："
              prop="path"
            >
              <el-input
                v-model="Selected.jump_config.path"
                placeholder="请输入，如：pages/index/index"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="selectedType === 'h5_link'"
              label="网页链接："
              prop="url"
            >
              <el-input
                v-model="Selected.jump_config.url"
                placeholder="请输入"
              ></el-input>

              <div class="tips">
                <i class="el-icon-warning-outline"></i>
                仅支持已绑定公众号文章链接，社团云 H5
                端链接；其余链接需要在微信小程序后台配置业务域名才能正常显示。
              </div>
            </el-form-item>

            <el-form-item
              v-if="selectedType === 'phone_link'"
              label="电话："
              prop="url"
            >
              <el-input
                v-model="Selected.jump_config.url"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span v-if="!readonly" slot="footer">
        <el-button size="medium" @click="openDialog = false">取消</el-button>
        <el-button
          size="medium"
          type="primary"
          @click="submit"
          :loading="saveLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <two-code-dialog
      :show-code="openCodeDialog"
      :codes="codes"
      @closeCode="closeCodeDialog"
    ></two-code-dialog>
  </div>
</template>

<script>
import {
  linkTypeList,
  linkList,
  AllSource,
  articleCategory,
  circleCategory,
  storeCategory,
} from '../../api/components'
import debounce from 'lodash/debounce'
import { copyText } from '@/base/utils/tool'
import { getLinkCode } from '../../api/search'
import MoreBtn from '../../../../base/components/Table/MoreBtn'
import TwoCodeDialog from '../Search/TwoCodeDialog'
import pagePathMixin from '../../mixins/pagePathMixin'
import { getURLLink } from '../../../common/api'
import { toQueryObject } from '../../utils/webview'
import ListImage from '@/base/components/List/ListImage'

export default {
  components: { TwoCodeDialog, MoreBtn, ListImage },
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  mixins: [pagePathMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    // 规定跳转链接类型的标识
    requestType: {
      type: Array,
      default: () => [],
    },
    // 规定要排除的跳转链接类型的标识
    exclude_requestType: {
      type: Array,
      default: () => [],
    },
    jump_type: {
      type: String,
      default: 'all',
    },
    link: {
      type: Object,
      default: null,
    },
    // 引用来源组件
    component: {
      type: String,
      default: '',
    },
    platform: String,
  },
  data() {
    return {
      loading: false, //加载
      linkType: '', // 链接类型
      selectedType: '',
      is_list: 0, // 是否是列表页
      thead: {
        // page_link: [
        //   { label: "页面名称", prop: "name", minWidth: 150 },
        //   { label: "页面层级", prop: "level", minWidth: 160 },
        // ],
        content_link: [{ label: '页面名称', prop: 'name', minWidth: 150 }],
        article_data: [
          { label: '标题', prop: 'name', minWidth: 150 },
          { label: '来源', prop: 'source_name', minWidth: 110 },
          { label: '发布时间', prop: 'create_time', minWidth: 160 },
        ],
        article_subject_data: [
          { label: '专题名称', prop: 'name', minWidth: 150 },
          { label: '分类', prop: 'category_name', minWidth: 110 },
          { label: '创建时间', prop: 'create_time', minWidth: 160 },
        ],
        activity_menu_data: [
          { label: '活动名称', prop: 'name', minWidth: 150 },
          { label: '活动时间', prop: 'create_time', minWidth: 160 },
        ],
        cyc_brand_data: [
          { label: '专题名称', prop: 'name', minWidth: 150 },
          { label: '创建时间', prop: 'create_time', minWidth: 160 },
        ],
        cyc_multi_level_data: [
          { label: '组织名称', prop: 'name', minWidth: 150 },
          { label: '创建时间', prop: 'create_time', minWidth: 160 },
        ],
        circle_menu_data: [
          { label: '资源内容', prop: 'name', minWidth: 110 },
          { label: '内容类型', prop: 'content_type_text', minWidth: 110 },
          { label: '内容分类', prop: 'category_name', minWidth: 110 },
          { label: '发布人', prop: 'nickname', minWidth: 110 },
        ],
        cyc_organization_link: [
          { label: '页面名称', prop: 'name', minWidth: 150 },
        ],
        wx_store_data: [
          { label: '商品名称', prop: 'name', minWidth: 150 },
          { label: '商品分类', prop: 'category_name', minWidth: 150 },
        ],
        introduce_link: [{ label: '页面名称', prop: 'name', minWidth: 150 }],
      },
      searchKey: {
        page_link: '页面',
        article_data: '文章',
        article_subject_data: '专题',
        activity_menu_data: '活动',
        circle_menu_data: '资源',
        cyc_brand_data: '专题',
        cyc_organization_link: '页面',
        introduce_link: '页面',
        wx_store_data: '商品',
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        appid: [
          // { required: true, message: "请输入AppID", trigger: "blur" },
          { validator: this.appidValidate, trigger: 'blur' },
        ],
        path: [
          //   { required: true, message: '请输入小程序链接', trigger: 'blur' },
          { validator: this.pathValidate, trigger: 'blur' },
        ],
        url: [{ required: true, validator: this.urlValidate, trigger: 'blur' }],
      },
      mp_link_form: null, // 小程序链接缓存
      h5_link_form: null, // 跳转链接缓存
      phone_link_form: null, // 电话链接缓存
      linkOptions: [], // 链接类型选项
      linkOptionsLoading: false,
      linkList: [], // 链接列表
      titleList: null,
      pageId: '',
      getable: true, // 是否还可以获取列表
      //筛选对象
      filterForm: {
        index: '',
        belong_feature: '',
        project_id: this.$route.params.id,
        category_id: '', // 分类
        is_demand: '', // 资源类型
        keyword: '', //关键词
        page_size: 10, //每页多少条数据
        page: 1,
        level: '',
        source_id: '',
        jump_type: 'view',
      },
      AllSourceList: [],
      categoryArr: [], // 分类选项
      demandArr: [
        { name: '供应', id: 0 },
        { name: '需求', id: 1 },
      ], // 资源类型
      Selected: {},
      saveLoading: false,
      jumpLinkModel: require('@/modules/app-design/assets/jsons/jump-link.json'),
      resetLinkTypeList: false, //是否重新更新连接类型导航列表

      codes: [],
      openCodeDialog: false,
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
    isWxApplet() {
      return this.$store.getters.tenant.features.includes('wx_applet')
    },
    // linkTypeOption() {
    //   return this.linkOptions.find((el) => el.index === this.selectedType)
    //     ?.children;
    // },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getable = true
        this.filterForm.brand_id = val.id
        this.filterForm.status = val.status
        this.filterForm.mark = val.mark
        this.filterForm.keyword = ''
        this.filterForm.page = 1
        this.getAllSourceList()
        this.$nextTick(() => {
          this.getLinkTypeList()
        })
      } else {
        this.Selected = null
        this.mp_link_form = this.h5_link_form = null
      }
    },
    selectedType(val) {
      switch (val) {
        case 'article_subject_data':
          this.categoryArr = []
          this.getArticleCategory()
          break
        case 'circle_menu_data':
          this.categoryArr = []
          this.getCircleCategory()
          break
        case 'wx_store_data':
          this.categoryArr = []
          this.getStoreCategory()
          break
      }

      this.$nextTick(() => {
        if (this.$refs.select) this.$refs.select.clearValidate()
      })
    },
    Selected: {
      deep: true,
      handler(val) {
        switch (this.selectedType) {
          case 'mp_link':
            if (val) {
              this.mp_link_form = val.jump_config
            }
            break
          case 'h5_link':
            if (val) {
              this.h5_link_form = val.jump_config
            }
            break
          case 'phone_link':
            if (val) {
              this.phone_link_form = val.jump_config
            }
            break
        }
      },
    },
    'filterForm.is_demand'(val) {
      this.categoryArr = []
      this.getCircleCategory()
    },
    // 监听请求参数变化
    requestType: {
      deep: true,
      handler(val) {
        this.resetLinkTypeList = true
      },
    },
  },
  methods: {
    getURLLink(row) {
      this.btnLoading = true
      const { jump_link } = row
      const { path: _path } = jump_link.jump_config
      const [path, query] = _path.split('?')
      getURLLink(path.startsWith('/') ? path.slice(1) : path, query)
        .then(({ data, msg }) => {
          copyText(data.data.url_link)
            .then(() => {
              this.$message.success('复制成功')
            })
            .catch((err) => {
              this.$message.error(err.message)
            })
        })
        .catch(() => {})
        .finally(() => {
          this.btnLoading = false
        })
    },
    showTwoCodes(row) {
      this.openCodeDialog = true
      Promise.all([this.showCode(row, 'trial'), this.showCode(row, 'release')])
        .then((res) => {
          this.codes = res
        })
        .catch((err) => {
          console.log(err)
        })
    },
    showH5Code(row) {
      this.showCode(row, 'h5')
        .then(({ data }) => {
          // data.qrcode
        })
        .catch(() => {})
    },
    openLink(href) {
      window.open(href)
    },
    readPath(obj) {
      // h5
      if (this.$props.platform === 'h5') {
        if (obj.mp_link) {
          copyText(obj.mp_link).then((res) => {
            this.$message.success('复制成功')
          })
          return
        }
      }
      // 小程序
      const link = this.getWxLink(obj)

      const pathText = this.getWxPath(link)

      if (!pathText) return

      copyText(pathText)
        .then(({ data, msg }) => {
          this.$message.success('复制成功')
        })
        .catch(() => {})
    },
    // 获取链接类型列表
    getLinkTypeList() {
      // console.log(this.link, 'this.link')
      let isJumpLink = this.link && ['mp', 'web'].includes(this.link.jump_type)
      let isPhone = this.link && this.link.jump_type === 'phone'
      if (this.linkOptions.length && !this.resetLinkTypeList) {
        console.log(this.linkOptions[0])
        if (this.linkOptions[0].children) {
          this.selectedType = this.linkOptions[0].children[0].index
        }
        this.selectedType = !isJumpLink
          ? this.selectedType
          : this.link.jump_type === 'mp'
          ? 'mp_link'
          : 'h5_link'
        this.selectedType = isPhone ? 'phone_link' : this.selectedType
        this.handleLinkType(true)
        return
      }
      this.linkOptionsLoading = true
      linkTypeList({
        tabs: this.requestType,
        exclude_tabs: this.exclude_requestType,
        platform: this.$route.params?.platform
          ? this.$route.params.platform
          : this.platform || 'mp-weixin',
        other_system_page: 1,
        // other_system_page:
        //   this.component === 'st-user-center-nav' ||
        //   this.component === 'st-default-user-center-nav'
        //     ? 1
        //     : 0,
      })
        .then((res) => {
          this.resetLinkTypeList = false
          this.linkOptionsLoading = false
          const { data: resData } = res
          let data
          if (resData.length === 1) {
            data = resData[0].children
            this.selectedType = data[0].index
          } else {
            data = resData
            this.selectedType = data[0].children[0].index
          }
          this.linkOptions = data

          this.selectedType = !isJumpLink
            ? this.selectedType
            : this.link.jump_type === 'mp'
            ? 'mp_link'
            : 'h5_link'
          this.selectedType = isPhone ? 'phone_link' : this.selectedType
          // console.log(this.selectedType, isPhone, 'this.selectedType')
          this.handleLinkType(true)
        })
        .catch(() => {
          this.linkOptionsLoading = false
          this.resetLinkTypeList = false
        })
    },
    // 获取来源列表
    getAllSourceList() {
      if (this.AllSourceList.length) return
      AllSource({ portal_user_id: 0 })
        .then((res) => {
          this.AllSourceList = res.data
        })
        .catch((err) => {})
    },
    // 获取文章专题分类
    getArticleCategory() {
      articleCategory()
        .then((res) => {
          this.categoryArr = res.data
        })
        .catch()
    },
    // 获取资源分类
    getCircleCategory() {
      circleCategory({
        is_demand:
          this.filterForm.is_demand !== '' ? this.filterForm.is_demand : -1,
      })
        .then((res) => {
          this.categoryArr = res.data
        })
        .catch()
    },
    // 获取小商店商品分类
    getStoreCategory() {
      storeCategory()
        .then((res) => {
          this.categoryArr = res.data
        })
        .catch()
    },
    // 获取链接列表
    getLinkList(init) {
      if (!this.getable || this.loading) {
        if (init) {
        }
        return
      }
      this.loading = true
      const requestData = {
        ...this.filterForm,
        platform: this.$route.params?.platform
          ? this.$route.params.platform
          : this.platform || 'mp-weixin',
      }
      requestData.level =
        this.filterForm.level === '' ? -1 : this.filterForm.level
      requestData.source_id =
        this.filterForm.source_id === '' ? -1 : this.filterForm.source_id
      requestData.is_demand =
        this.filterForm.is_demand === '' ? -1 : this.filterForm.is_demand
      requestData.category_id =
        this.filterForm.category_id === '' ? -1 : this.filterForm.category_id
      requestData.jump_type = this.jump_type

      if (requestData.index === 'custom_page') {
        requestData.pageset = this.$route.query.pageset || 'preview'
      }

      if (requestData.page === 1) {
        this.titleList = null
        this.linkList = []
      }

      linkList(requestData)
        .then((res) => {
          const { data } = res
          let formatData = data.data.map((el) => {
            el.jump_link = this.$mergeModel(this.jumpLinkModel, el.jump_link)
            return el
          })
          // console.log("data", data);
          if (requestData.page === 1) {
            this.linkList = formatData
            this.titleList = data.titles // {index: [], value: []}
          } else {
            this.linkList = [...this.linkList, ...formatData]
          }
          if (data.data.length < 10) this.getable = false
          this.filterForm.page++
          this.loading = false

          if (init) {
            if (this.link.jump_type === 'view') {
              if (this.link.jump_config?.view) {
                let view = this.link.jump_config.view
                this.$nextTick(() => {
                  var elem = document.getElementById(
                    view.system_page || view.id
                  )
                  if (elem) elem.scrollIntoView()
                  this.Selected = JSON.stringify(this.link)
                })
              }
            }
          }

          this.$nextTick(() => {
            this.$refs.multipleTable?.doLayout()
          })
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 验证网页链接/电话号码
    urlValidate(rule, value, callback) {
      if (value === '') {
        if (this.Selected.jump_type === 'phone') {
          callback(new Error('请输入电话号码'))
        } else {
          callback(new Error('请输入网页链接'))
        }
      } else {
        callback()
      }
    },
    appidValidate(rule, value, callback) {
      if (!value && !this.Selected.jump_config.path) {
        callback(new Error('请输入 AppID 或小程序链接'))
      } else {
        callback()
        this.$refs.select.clearValidate('path')
      }
    },
    pathValidate(rule, value, callback) {
      if (!value && !this.Selected.jump_config.appid) {
        callback(new Error('请输入 AppID 或小程序链接'))
      } else {
        callback()
        this.$refs.select.clearValidate('appid')
      }
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      this.Selected = val
    },
    // 点击页面数据选中
    checked(e) {
      e.target.lastElementChild.click()
    },
    // 筛选操作
    handleFilter() {
      this.getable = true
      this.filterForm.page = 1
      this.getLinkList()
    },
    // 触底刷新
    LoadMore: debounce(function () {
      this.getLinkList()
    }, 100),
    // 确定
    submit() {
      if (['h5_link', 'mp_link'].includes(this.selectedType)) {
        this.$refs.select.validate((valid) => {
          if (valid) {
            if (
              this.selectedType === 'mp_link' &&
              !this.Selected.jump_config.appid
            ) {
              this.Selected.jump_type = 'page'
            }
            this.$emit(
              'change',
              this.$mergeModel(this.jumpLinkModel, this.Selected)
            )
            // console.log("change", this.Selected);
            this.openDialog = false
          } else {
            return false
          }
        })
      } else if (this.selectedType === 'phone_link') {
        this.$refs.select.validate((valid) => {
          if (valid) {
            this.$emit(
              'change',
              this.$mergeModel(this.jumpLinkModel, this.Selected)
            )
            this.openDialog = false
          } else {
            return false
          }
        })
      } else {
        if (JSON.stringify(this.Selected) === '{}') {
          this.$message.info('请选择数据项')
        } else {
          if (this.requestType !== 2) {
            if (this.is_list === 2) {
              this.$emit(
                'change',
                this.$mergeModel(this.jumpLinkModel, JSON.parse(this.Selected))
              )
            } else {
              this.$emit(
                'change',
                this.$mergeModel(this.jumpLinkModel, {
                  ...this.Selected.jump_link,
                })
              )
              // console.log("change", { ...this.Selected.jump_link });
            }
          } else {
            this.$emit(
              'change',
              this.$mergeModel(this.jumpLinkModel, { ...this.Selected })
            )
            // console.log("change", { ...this.Selected });
          }
          this.openDialog = false
        }
      }
    },
    // 清除弹窗数据
    clearDialog(init) {
      this.$nextTick(() => {
        if (this.list === 2) {
          this.$refs.multipleTable.bodyWrapper.scrollTop = 0
        }
      })
      this.filterForm.keyword = ''
      this.filterForm.level = ''
      this.filterForm.source_id = ''
      this.filterForm.is_demand = ''
      this.filterForm.category_id = ''
      this.filterForm.page = 1
      this.getable = true
      this.getLinkList(init)
      this.saveLoading = false
    },
    handleLinkType(init) {
      let val = this.selectedType
      // console.log('link_type', val)
      this.Selected = {}
      // let currentOptions = this.selectedTypeOption;
      // let selectedTypeIndex = 0;
      // if (init === true) {
      //   let key = ''
      //   if (this.link?.jump_type === 'view') {
      //     if (this.link.jump_config?.view) {
      //       let view = this.link.jump_config.view
      //       if (view.is_system) {
      //         key = 'system_page'
      //       } else {
      //         key = 'custom_page'
      //       }
      //     }
      //   } else if (
      //     this.link?.jump_type === 'mp' ||
      //     this.link?.jump_type === 'page'
      //   ) {
      //     key = 'mp_link'
      //   } else if (this.link?.jump_type === 'web') {
      //     key = 'h5_link'
      //   }
      //   // selectedTypeIndex = currentOptions.findIndex((op) => op.index === key);
      // }
      this.selectedType = val
      this.handleSelectedType(init)
    },
    handleSelectedType(init) {
      let val = this.selectedType
      // console.log('selectedType', val);
      // const linkTypeData = this.linkOptions.find(
      //   (el) => el.index === this.selectedType
      // );
      let selectTypeData = {}
      this.linkOptions.forEach((el) => {
        if (el.index === val) {
          selectTypeData = el
        } else if (el.children) {
          let typeData = el.children.find((item) => item.index === val)
          if (typeData) {
            selectTypeData = typeData
          } else {
            el.children.forEach((item) => {
              item.children?.forEach((subItem) => {
                if (subItem.index === val) {
                  selectTypeData = subItem
                }
              })
            })
          }
        }
      })
      let hasInitJumpConfig = init && this.link && this.link.jump_config
      if (val === 'h5_link') {
        this.Selected = this.$mergeModel(this.jumpLinkModel, {
          jump_type: 'web',
          jump_config: {
            name: hasInitJumpConfig
              ? this.link.jump_config.name
              : this.h5_link_form
              ? this.h5_link_form.name
              : '',
            url: hasInitJumpConfig
              ? this.link.jump_config.url
              : this.h5_link_form
              ? this.h5_link_form.url
              : '',
          },
        })
      } else if (val === 'mp_link') {
        this.Selected = this.$mergeModel(this.jumpLinkModel, {
          jump_type: 'mp',
          jump_config: {
            name: hasInitJumpConfig
              ? this.link.jump_config.name
              : this.mp_link_form
              ? this.mp_link_form.name
              : '',
            appid: hasInitJumpConfig
              ? this.link.jump_config.appid
              : this.mp_link_form
              ? this.mp_link_form.appid
              : '',
            path: hasInitJumpConfig
              ? this.link.jump_config.path
              : this.mp_link_form
              ? this.mp_link_form.path
              : '',
          },
        })
      } else if (val === 'phone_link') {
        this.Selected = this.$mergeModel(this.jumpLinkModel, {
          jump_type: 'phone',
          jump_config: {
            name: '电话',
            url: hasInitJumpConfig
              ? this.link.jump_config.url
              : this.phone_link_form
              ? this.phone_link_form.url
              : '',
          },
        })
      }
      this.is_list = selectTypeData.is_list
      if (this.is_list) {
        this.filterForm.index = val
        // this.filterForm.belong_feature = linkTypeData.belong_feature;
        this.clearDialog(init)
      }
    },
    selectTypeOption(item) {
      this.selectedType = item.index
      this.handleSelectedType()
    },
    closeCodeDialog() {
      this.codes = []
      this.openCodeDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
.tips {
  font-size: 14px;
  color: #b3b3b3;
  line-height: 24px;
  margin-top: 8px;
}
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.link-type-select {
  display: flex;
  align-items: center;

  .select-label {
    margin-right: 14px;
  }
}

.link-content {
  min-height: 347px;
  display: flex;
  border-top: 1px #e8e8e8 solid;
  margin: 0 -20px;
  // margin-top: 25px;

  .table-type-list {
    width: 165px;
    height: 345px;
    border-right: 1px #e8e8e8 solid;
    display: flex;
    flex-direction: column;

    .menu-scrollbar {
      flex-grow: 1;
      flex: 1;
      height: 1px;

      ::v-deep.el-scrollbar__wrap {
        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
      }

      ::v-deep.el-scrollbar__thumb {
        display: none;
      }
    }

    .type-list-item {
      width: 100%;
      height: 46px;
      padding: 16px 24px;
      cursor: pointer;

      .item-name {
        padding: 0 6px;
        color: #3a3a3a;
      }

      &.active {
        background-color: rgba(53, 118, 255, 0.15);

        .item-name {
          border-left: 2px #3576ff solid;
          color: #3576ff;
        }
      }
    }
  }

  .select-table {
    width: calc(100% - 165px);

    .head-filter {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 32px 24px;

      .filter-item {
        width: 250px;
        margin-right: 24px;
        display: flex;
        align-items: center;

        p {
          margin-right: 14px;
        }

        .el-input,
        .el-select {
          width: 161px;

          ::v-deep .el-input__inner {
            width: 161px;
            height: 32px;
          }

          ::v-deep.el-input__icon {
            line-height: 32px;
          }
        }
      }
    }

    .small-form {
      padding: 47px;
    }
  }
}

.member-list {
  min-height: 300px;

  ::v-deep.el-table__row {
    cursor: pointer;
  }

  ::v-deep.cell {
    padding-left: 20px;
  }
}

.page-list {
  height: 250px;
  overflow: hidden auto;

  .page-radio {
    width: 100%;
    margin-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    font-size: 14px;
    color: #606266;

    ::v-deep.el-radio__label {
      padding: 0px;
    }
  }

  .lv-one-pages {
    .lv-one-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .page-radio {
        padding: 14px 37px 14px 27px;
      }

      &:hover {
        background: #f5f7fa;
        cursor: pointer;
      }
    }

    .lv-two-pages {
      .lv-two-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .page-radio {
          padding: 14px 37px 14px 41px;
        }

        &:hover {
          background: #f5f7fa;
          cursor: pointer;
        }
      }

      .lv-three-pages {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .page-radio {
          padding: 14px 37px 14px 49px;
        }

        &:hover {
          background: #f5f7fa;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
