<!--
 * @Author: dongjia
 * @Date: 2021-08-09 10:26:56
 * @LastEditTime: 2021-08-27 10:05:56
 * @LastEditors: aleaner
 * @Description: 业务组件选择业务数据列表设置
 * @FilePath: \app-design\components\Form\StDragDataList.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="drag-list st-drag-data-list">
    <div>
      <p class="list-title">{{ title || "添加数据" }}</p>
      <p class="form-tip">拖拽调整展示顺序</p>
    </div>
    <draggable
      v-if="dataList"
      v-model="dataList"
      class="nav-list"
      animation="500"
      draggable=".nav-item"
    >
      <template v-if="dataList.length > 0 && dataList[0].name">
        <div class="nav-item" v-for="(data, index) in dataList" :key="index">
          <div class="data-name">{{ itemTitle }}：{{ data.name }}</div>
          <div class="data-btn">
            <div
              class="editText"
              @click="handleEdit(data)"
              v-if="formType !== 'st-spirit-list' && showEdit"
            >
              编辑
            </div>
            <div class="deleteText" @click="dataList.splice(index, 1)">
              {{ deleteText || "删除" }}
            </div>
          </div>
        </div>
      </template>
      <div
        class="nav-item-add"
        :style="{ marginTop: dataList.length > 0 ? '24px' : '0' }"
      >
        <el-button
          style="width: 100%"
          size="mini"
          type="primary"
          plain
          @click="dataSelectDialog = true"
        >
          {{ addButtonText }}
        </el-button>
      </div>
    </draggable>
    <dataSelector
      v-model="dataSelectDialog"
      :selected="dataList"
      :dataType="formType"
      @change="dataChange"
      :form="{...pageData, ...myFilterForm}"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import dataSelector from "../Normal/dataSelector.vue";
export default {
  name: "StDragDataList",
  components: {
    draggable,
    dataSelector,
  },
  model: {
    prop: "list",
    event: "listChange",
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    formType: {
      type: String,
      default: "st-brand-list",
    },
    itemTitle: {
      type: String,
      default: "专题名称",
    },
    addButtonText: {
      type: String,
      default: "添加专题数据",
    },
    title: {
      type: String,
      default: "",
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    deleteText: {
      type: String,
      default: "",
    },
    // 如 formType 为 st-banner 时传
    pageData:{
      type: Object,
      default:() => ({})
    },
    // 2024年10月08日16:31:15 新增
    getFilterForm: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      dataSelectDialog: false,
      unDraggable: false,
      visible: false,
      routerName: {
        "st-brand-list": "editBrand",
        "st-banner": "edit-advert",
        "st-spirit-list": "AddSpirit",
        "st-article-list": "EditArticle",
        "st-special-article": "ArticleSubjectAddEdit",
        "st-activity-list": "ActivityDetail",
        // "st-shop-goods": "WxStoreGoodsDetail",
        "st-channels-video-list": "WxAccountVideos",
        "st-channels-live-list": "WxAccountLive",
        "st-donation-list": "donationProject",
        "st-business-association-list": "BusinessAssociationDetail",
        "st-shop-goods": "GoodsDetail",
        "st-college-teacher-list": "AddCollegeTeacher",
      },
    };
  },
  computed: {
    dataList: {
      get() {
        return this.list;
      },
      set(val) {
        this.$emit("listChange", val);
      },
    },
    jumpType() {
      return this.formType === "st-bottom-nav"
        ? this.current_jumptype
        : this.jump_type;
    },
    myFilterForm() {
      return typeof this.getFilterForm === 'function' ? this.getFilterForm() : {}
    }
  },
  watch: {
    dataList: {
      handler(val) {
        this.$emit("listChange", val);
      },
      deep: true,
    },
  },
  methods: {
    // 点击选择数据
    dataChange(val) {
      this.dataList = val;
    },
    // 编辑数据
    handleEdit(row) {
      if (["st-special-article", "st-article-list"].includes(this.formType)) {
        let routerData = this.$router.resolve({
          name: this.routerName[this.formType],
          query: {
            id: row.id,
            hash: 1,
            portal_user_id: 0,
            come_from: "ArticleList",
          },
        });
        window.open(routerData.href, "_blank");
      } else if (["st-business-association-list"].includes(this.formType)) {
        let routerData = this.$router.resolve({
          name: this.routerName[this.formType],
          params: { detailId: row.id, type: "edit" },
        });
        window.open(routerData.href, "_blank");
      } else if (["st-shop-goods"].includes(this.formType)) {
        // 商城
        let routerData = this.$router.resolve({
          name: this.routerName[this.formType],
          params: { id: row.id },
        });
        window.open(routerData.href, "_blank");
      }else {
        let routerData = this.$router.resolve({
          name: this.routerName[this.formType],
          params: { id: row.id, type: "edit" },
        });
        window.open(routerData.href, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.st-drag-data-list {
  
}
.list-title {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 14px;
  padding-left: 20px;
}
.form-tip {
  font-size: 12px;
  color: #c0c4cc;
  padding-top: 8px;
  padding-bottom: 11px;
  padding-left: 20px;

}
.nav-list {
  background-color: #f6f9ff;
  // margin: 0 -19px 0 -24px;
  padding: 12px;
  margin-bottom: 20px;
}
.nav-item-add {
  width: 100%;
  text-align: center;
}
.nav-item {
  width: 100%;
  padding: 8px 24px;
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .data-name {
    flex: 1;
    color: #1a1a1a;
    font-size: 12px;
    font-weight: 400;
    min-width: 0;
    @include nowrap();
  }
  .data-btn {
    font-size: 12px;
    font-weight: 400;
    display:flex;
    
    .deleteText {
      color: #ff4949ff;
      cursor: pointer;
    }
    .editText {
      color: $primary;
      margin-right:5px;
      cursor: pointer;

    }
  }
}

.nav-item + .nav-item {
  margin-top: 12px;
}
</style>
